import './App.css';
import {Admin, Resource} from "react-admin";
import {authProvider} from './authProvider';
import {i18nProvider} from './i18nProvider';
import EventsIcon from '@mui/icons-material/RateReview';
import RealtimeIcon from '@mui/icons-material/AccessTime';
import ReportIcon from '@mui/icons-material/InsertDriveFile';
import BusIcon from '@mui/icons-material/DirectionsBus';
import UserIcon from '@mui/icons-material/Person';
import {
    VehiclesList,
    VehicleEdit,
    VehicleCreate,
    VehicleShow,
} from './components/vehicles_components';
import {
    UsersList,
    UserEdit,
    UserCreate,
    UserContextCreate,
    UserDelegationEdit,
    UserDelegationCreate
} from './components/administration_components';
import {
    EventsList,
    EventEdit,
    EventShow,
    StrikeList,
    StrikeEdit,
    StrikeCreate,
    ReportsList,
    JourneyList,
    JourneyShow,
    OutageIntervalsList,
    OutageIntervalsEdit,
    OutageIntervalsCreate,
    ExtraBusInformationList,
    ExtraBusInformationEdit,
    ManualPassengerCountingList,
    ManualPassengerCountingEdit,
    ManualPassengerCountingCreate,
    SegmentChangesLogList,
    SegmentChangesLogEdit,
    SegmentChangesLogCreate,
    SasaJourneyCodesList,
    SegmentChangeList, ItcsEfaList,
} from './components/operators_portal_components';
import dataProviders from './sit-data-provider/combinedDataProviders.js';
import SitLogin from './components/SitLogin';
import MyLayoutJs from "./MyLayout";

//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');


//const App = () => <Admin dataProvider={dataProvider} />;
const App = () => (
    <Admin
        layout={MyLayoutJs}
        loginPage={SitLogin}
        authProvider={authProvider}
        dataProvider={dataProviders}
        i18nProvider={i18nProvider}
    >
        {permissions => [
            permissions.contexts.find(permission => permission.identifier === 'operators_portal')
                ? permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'sta_operator'
                    ?
                    <Resource name="operators_portal.line_events_vw" list={EventsList} show={EventShow} edit={EventEdit} icon={EventsIcon}/>
                    :
                    <Resource name="operators_portal.line_events_vw" list={EventsList} show={EventShow} icon={EventsIcon}/>
                : null,
            permissions.contexts.find(permission => permission.identifier === 'operators_portal' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? <Resource name="operators_portal.rt_events_vw" list={JourneyList} show={JourneyShow}
                            icon={RealtimeIcon}/> : null,
            permissions.contexts.find(permission => permission.identifier === 'operators_portal_sb_ritten' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? permissions.role === 'sitadmin' || permissions.role === 'operator'
                    ?
                    <Resource name="operators_portal.outage_intervals" list={OutageIntervalsList} edit={OutageIntervalsEdit}
                              create={OutageIntervalsCreate}/>
                    : <Resource name="operators_portal.outage_intervals" list={OutageIntervalsList}/>
                : null,
            permissions.contexts.find(permission => permission.identifier === 'operators_portal_sb_ritten' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? permissions.role === 'sitadmin' || permissions.role === 'operator'
                    ? <Resource name="operators_portal.manual_passenger_counting" list={ManualPassengerCountingList}
                                edit={ManualPassengerCountingEdit} create={ManualPassengerCountingCreate}/>
                    : <Resource name="operators_portal.manual_passenger_counting" list={ManualPassengerCountingList}/>
                : null,
            //
            // permissions.contexts.find(permission => permission.identifier === 'operators_portal_segment_changes_log')
            //     ? permissions.role === 'sitadmin' || permissions.role === 'sta_operator'
            //         ? <Resource name="operators_portal.segment_changes_log" list={SegmentChangesLogList}
            //                     edit={SegmentChangesLogEdit} create={SegmentChangesLogCreate}/>
            //         : <Resource name="operators_portal.segment_changes_log" list={SegmentChangesLogList}/>
            //     : null,
            permissions.contexts.find(permission => permission.identifier === 'operators_portal' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'sta_operator'
                    ?
                    <Resource name="operators_portal.segment_changes" list={SegmentChangeList}/>
                    :
                    <Resource name="operators_portal.segment_changes" show={SegmentChangeList}/>
                : null,
            ,
            permissions.contexts.find(permission => permission.identifier === 'operators_portal' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? permissions.role === 'sitadmin' || permissions.role === 'operator'
                    ? <Resource name="operators_portal.strike" list={StrikeList} edit={StrikeEdit} create={StrikeCreate}/>
                    : <Resource name="operators_portal.strike" list={StrikeList}/>
                : null,
            permissions.contexts.find(permission => permission.identifier === 'operators_portal' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? <Resource name="operators_portal.extra_bus_information_vw" list={ExtraBusInformationList}
                            edit={ExtraBusInformationEdit}/> : null,
            permissions.contexts.find(permission => permission.identifier === 'operators_portal' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? <Resource name="operators_portal.sasa_journey_codes_vw" list={SasaJourneyCodesList}/> : null,
            permissions.contexts.find(permission => permission.identifier === 'itcs_efa' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? <Resource name="operators_portal.itcs_efa" list={ItcsEfaList}/> : null,
            /*permissions.contexts.find(permission => permission.identifier === 'operators_portal')
                ? <Resource name="operators_portal.unplanned_changes" list={UnplannedChangesList} edit={UnplannedChangesEdit} create={UnplannedChangesCreate} /> : null,*/
            permissions.contexts.find(permission => permission.identifier === 'operators_portal')
                ? <Resource name="operators_portal.reports_vw" list={ReportsList} icon={ReportIcon}/> : null,
            /*permissions.contexts.find(permission => permission.identifier === 'operators_portal')
                ? <Resource name="operators_portal.manual_data_uploads" list={ManualUploadsList} /> : null,*/
            permissions.contexts.find(permission => permission.identifier === 'vehicles' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? permissions.role === 'sitadmin' || permissions.role === 'operator'
                    ? <Resource name="vehicles.vehicle" list={VehiclesList} edit={VehicleEdit} create={VehicleCreate}
                                icon={BusIcon}/>
                    : <Resource name="vehicles.vehicle" list={VehiclesList} show={VehicleShow} icon={BusIcon}/>
                : null,
            /*permissions.contexts.find(permission => permission.identifier === 'vehicles')
                ? <Resource name="vehicles.subconcessionaire" list={SubconcessionairesList} edit={SubconcessionaireEdit} create={SubconcessionaireCreate} icon={OperatorIcon} /> : null,*/
            /*permissions.contexts.find(permission => permission.identifier === 'vehicles')
                ? <Resource name="vehicles.operators_vw" list={OperatorsList} icon={OperatorIcon} /> : null,*/
            permissions.contexts.find(permission => permission.identifier === 'administration' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? <Resource name="administration.users" list={UsersList} edit={UserEdit} create={UserCreate}
                            icon={UserIcon}/> : null,
            permissions.contexts.find(permission => permission.identifier === 'administration' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? <Resource name="administration.user_contexts" create={UserContextCreate}/> : null,
            permissions.contexts.find(permission => permission.identifier === 'administration' && process.env.REACT_APP_BUILD_FLAVOR != 'trial')
                ? <Resource name="administration.user_delegations" edit={UserDelegationEdit}
                            create={UserDelegationCreate}/> : null,

            <Resource name="vehicles.constants"/>,
            <Resource name="vehicles.operators_vw"/>,
            <Resource name="administration.contexts"/>,
            <Resource name="operators_portal.constants"/>,
            <Resource name="operators_portal.operators_vw"/>,
            <Resource name="operators_portal.attachments"/>,
            <Resource name="operators_portal.report_attachments"/>,
            <Resource name="operators_portal.report_types"/>,
            <Resource name="operators_portal.lots_vw"/>,
            <Resource name="operators_portal.service_link_mvw"/>,
            <Resource name="operators_portal.segment_changes_filter_operational_context_vw"/>,
            <Resource name="operators_portal.mandants_vw"/>,
        ]}
    </Admin>
);

export default App;
