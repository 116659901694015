import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    NumberField,
    BooleanField,
    Edit,
    Show,
    SimpleShowLayout,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    EditButton,
    Create,
    DateInput,
    NumberInput,
    BooleanInput,
    NullableBooleanInput,
    ShowButton,
    Labeled
} from 'react-admin';
import { useTranslate, usePermissions } from 'react-admin';

import * as xlsx from 'xlsx';

const toXlsx = (records) => {
    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(records);
    xlsx.utils.book_append_sheet(wb, ws);

    xlsx.writeFile(wb, 'data_export.xlsx');
}

const xlsxVehiclesExporter = (records, fetchRelatedRecords) => {
    if(records && records[0]){

		const fetchRelatedRecordsPromises = [];
		fetchRelatedRecordsPromises.push(fetchRelatedRecords(records, 'display_hinten_typ_const_id', 'vehicles.constants'))
		fetchRelatedRecordsPromises.push(fetchRelatedRecords(records, 'display_seitlich_typ_const_id', 'vehicles.constants'))
		fetchRelatedRecordsPromises.push(fetchRelatedRecords(records, 'display_vorne_typ_const_id', 'vehicles.constants'))
		fetchRelatedRecordsPromises.push(fetchRelatedRecords(records, 'klasse_ministerialdekret_const_id', 'vehicles.constants'))
		fetchRelatedRecordsPromises.push(fetchRelatedRecords(records, 'layout_farbe_const_id', 'vehicles.constants'))
		fetchRelatedRecordsPromises.push(fetchRelatedRecords(records, 'niederflur_const_id', 'vehicles.constants'))
		fetchRelatedRecordsPromises.push(fetchRelatedRecords(records, 'treibstoff_const_id', 'vehicles.constants'))
		fetchRelatedRecordsPromises.push(fetchRelatedRecords(records, 'umweltklasse_const_id', 'vehicles.constants'))

		Promise.all(fetchRelatedRecordsPromises).then((constants) => {
			const new_records = records.map(record => ({
                ...record,
                display_hinten_typ_const_de: record.display_hinten_typ_const_id ? constants[0][record.display_hinten_typ_const_id].name_de : '',
                display_hinten_typ_const_it: record.display_hinten_typ_const_id ? constants[0][record.display_hinten_typ_const_id].name_it : '',
				display_seitlich_typ_const_de: record.display_seitlich_typ_const_id ? constants[1][record.display_seitlich_typ_const_id].name_de : '',
                display_seitlich_typ_const_it: record.display_seitlich_typ_const_id ? constants[1][record.display_seitlich_typ_const_id].name_it : '',
				display_vorne_typ_const_de: record.display_vorne_typ_const_id ? constants[2][record.display_vorne_typ_const_id].name_de : '',
                display_vorne_typ_const_it: record.display_vorne_typ_const_id ? constants[2][record.display_vorne_typ_const_id].name_it : '',
				klasse_ministerialdekret_const_de: record.klasse_ministerialdekret_const_id ? constants[3][record.klasse_ministerialdekret_const_id].name_de : '',
                klasse_ministerialdekret_const_it: record.klasse_ministerialdekret_const_id ? constants[3][record.klasse_ministerialdekret_const_id].name_it : '',
				layout_farbe_const_de: record.layout_farbe_const_id ? constants[4][record.layout_farbe_const_id].name_de : '',
                layout_farbe_const_it: record.layout_farbe_const_id ? constants[4][record.layout_farbe_const_id].name_it : '',
				niederflur_const_de: record.niederflur_const_id ? constants[5][record.niederflur_const_id].name_de : '',
                niederflur_const_it: record.niederflur_const_id ? constants[5][record.niederflur_const_id].name_it : '',
				treibstoff_const_de: record.treibstoff_const_id ? constants[6][record.treibstoff_const_id].name_de : '',
                treibstoff_const_it: record.treibstoff_const_id ? constants[6][record.treibstoff_const_id].name_it : '',
				umweltklasse_const_de: record.umweltklasse_const_id ? constants[7][record.umweltklasse_const_id].name_de : '',
                umweltklasse_const_it: record.umweltklasse_const_id ? constants[7][record.umweltklasse_const_id].name_it : '',
            }));
            toXlsx(new_records);
		});
    } else {
        toXlsx(records);
    }
};

const VehicleFilter = () => {
    const translate = useTranslate();
    return [
        <ReferenceInput source="betreiber_id" reference="vehicles.operators_vw" sort={{ field: 'name', order: 'ASC' }} perPage={100} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>,
        <TextInput source="matrikelnummer" alwaysOn/>,
        <NumberInput source="sta_matrikelnummer" alwaysOn/>,
        <TextInput source="kennzeichen" alwaysOn/>,
        <ReferenceInput source="umweltklasse_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "umweltklasse"}} alwaysOn>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>,
		<ReferenceInput source="treibstoff_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "treibstoff"}} alwaysOn>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>,
		<ReferenceInput source="niederflur_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "niederflur"}} alwaysOn>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>,
		<DateInput source="datum_erstzulassung@gte" label={translate('filter_from')} alwaysOn/>,
        <DateInput source="datum_erstzulassung@lte" label={translate('filter_to')} alwaysOn/>,
		<NullableBooleanInput source="has_ticketing_old" style={{width: '300px'}} alwaysOn/>
    ]
}

export const VehiclesList = () => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
    <List exporter={xlsxVehiclesExporter} filters={VehicleFilter()}>
        <Datagrid>
            <ReferenceField source="betreiber_id" reference="vehicles.operators_vw">
                <TextField source="name" />
            </ReferenceField>
			<TextField source="subkonzessionaer"/>
            <TextField source="matrikelnummer"/>
            <NumberField source="sta_matrikelnummer"/>
            <TextField source="kennzeichen"/>
            <ReferenceField source="treibstoff_const_id" reference="vehicles.constants" allowEmpty>
                <TextField source={translate('localizedName')} />
            </ReferenceField>
            <ReferenceField source="umweltklasse_const_id" reference="vehicles.constants" allowEmpty>
                <TextField source={translate('localizedName')} />
            </ReferenceField>
            <DateField source="datum_erstzulassung" />
            <DateField source="last_update_timestamp" showTime/>
			<BooleanField source="has_ticketing_old" />
			{
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator'))
                    ? (<EditButton/>)
                    : (<ShowButton/>)
            }
        </Datagrid>
    </List>)
}

const VehicleDetail = () => {
    const translate = useTranslate();
    return (
    <SimpleForm>
        <ReferenceInput source="betreiber_id" reference="vehicles.operators_vw">
            <SelectInput optionText="name" />
        </ReferenceInput>
		<TextInput source="subkonzessionaer" />
        <TextInput source="matrikelnummer" />
        <NumberInput source="sta_matrikelnummer" />
        <TextInput source="kennzeichen" />
        <TextInput source="hersteller" />
        <TextInput source="rahmennummer" />
        <DateInput source="datum_erstzulassung" />
        <ReferenceInput source="klasse_ministerialdekret_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "klasse_ministerialdekret"}}>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>
        <ReferenceInput source="treibstoff_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "treibstoff"}}>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>
        <ReferenceInput source="umweltklasse_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "umweltklasse"}}>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>
        <NumberInput source="motorleistung" step={1} />
        <BooleanInput source="partikelfilter_vorhanden" />
        <BooleanInput source="klimaanlage_vorhanden" />
        <BooleanInput source="zugang_behinderte" />
        <BooleanInput source="halterung_ski_rad" />
		<ReferenceInput source="layout_farbe_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "layout_type"}}>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>
        <NumberInput source="fahrzeuglaenge" step={0.01} />
        <NumberInput source="anzahl_tueren" step={1} />
        <NumberInput source="plaetze_gesamt" step={1} />
        <NumberInput source="sitzplaetze" step={1} />
        <NumberInput source="stehplaetze" step={1} />
        <NumberInput source="plaetze_rollstuhl" step={1} />
		<NumberInput source="plaetze_kinderwagen" step={1} />
        <ReferenceInput source="niederflur_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "niederflur"}}>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>
		<BooleanInput source="has_ticketing_old" />
		<BooleanInput source="has_ticketing_new" />
        <BooleanInput source="display_vorne_vorhanden" />
        <ReferenceInput source="display_vorne_typ_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "display_type"}}>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>
        <TextInput source="display_vorne_aufloesung" />
        <BooleanInput source="display_hinten_vorhanden" />
        <ReferenceInput source="display_hinten_typ_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "display_type"}}>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>
        <TextInput source="display_hinten_aufloesung" />
        <BooleanInput source="display_seitlich_vorhanden" />
        <ReferenceInput source="display_seitlich_typ_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "display_type"}}>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>
        <TextInput source="display_seitlich_aufloesung" />
        <BooleanInput source="display_infotainment_vorhanden" />
        <NumberInput source="display_infotainment_groesse" step={0.1} />
		<BooleanInput source="display_fahrgast_vorhanden" />
        <NumberInput source="display_fahrgast_groesse" step={0.1} />
        <BooleanInput source="videoueberwachung_vorhanden" />
        <BooleanInput source="akustisches_ansagesystem_vorhanden" />
        <BooleanInput source="fgzs_vorhanden" />
        <NumberInput source="fgzs_anzahl_tueren" step={1} />
        <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
            <DateField source="last_update_timestamp" showTime/>
        </Labeled>
    </SimpleForm>)
}

export const VehicleEdit = () => {
    return (
    <Edit>
        <VehicleDetail/>
    </Edit>)
}

export const VehicleShow = () => {
    const translate = useTranslate();
    return (
    <Show>
        <SimpleShowLayout>
            <ReferenceField source="betreiber_id" reference="vehicles.operators_vw">
				<TextField source="name" />
			</ReferenceField>
            <TextField source="subkonzessionaer" />
            <TextField source="matrikelnummer" />
            <NumberField source="sta_matrikelnummer" />
            <TextField source="kennzeichen" />
            <TextField source="hersteller" />
            <TextField source="rahmennummer" />
            <DateField source="datum_erstzulassung" />
			<ReferenceField source="klasse_ministerialdekret_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "klasse_ministerialdekret"}} allowEmpty>
				<TextField source={translate('localizedName')} />
			</ReferenceField>
			<ReferenceField source="treibstoff_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "treibstoff"}} allowEmpty>
				<TextField source={translate('localizedName')} />
			</ReferenceField>
			<ReferenceField source="umweltklasse_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "umweltklasse"}} allowEmpty>
				<TextField source={translate('localizedName')} />
			</ReferenceField>
			<NumberField source="motorleistung" step={1} />
			<BooleanField source="partikelfilter_vorhanden" />
			<BooleanField source="klimaanlage_vorhanden" />
			<BooleanField source="zugang_behinderte" />
			<BooleanField source="halterung_ski_rad" />
			<ReferenceField source="layout_farbe_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "layout_type"}} allowEmpty>
				<TextField source={translate('localizedName')} />
			</ReferenceField>
			<NumberField source="fahrzeuglaenge" step={0.01} />
			<NumberField source="anzahl_tueren" step={1} />
			<NumberField source="plaetze_gesamt" step={1} />
			<NumberField source="sitzplaetze" step={1} />
			<NumberField source="stehplaetze" step={1} />
			<NumberField source="plaetze_rollstuhl" step={1} />
			<NumberField source="plaetze_kinderwagen" step={1} />
			<ReferenceField source="niederflur_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "niederflur"}} allowEmpty>
				<TextField source={translate('localizedName')} />
			</ReferenceField>
			<BooleanField source="has_ticketing_old" />
			<BooleanField source="has_ticketing_new" />
			<BooleanField source="display_vorne_vorhanden" />
			<ReferenceField source="display_vorne_typ_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "display_type"}} allowEmpty>
				<TextField source={translate('localizedName')} />
			</ReferenceField>
			<TextField source="display_vorne_aufloesung" />
			<BooleanField source="display_hinten_vorhanden" />
			<ReferenceField source="display_hinten_typ_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "display_type"}} allowEmpty>
				<TextField source={translate('localizedName')} />
			</ReferenceField>
			<TextField source="display_hinten_aufloesung" />
			<BooleanField source="display_seitlich_vorhanden" />
			<ReferenceField source="display_seitlich_typ_const_id" reference="vehicles.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: "display_type"}} allowEmpty>
				<TextField source={translate('localizedName')} />
			</ReferenceField>
			<TextField source="display_seitlich_aufloesung" />
			<BooleanField source="display_infotainment_vorhanden" />
			<NumberField source="display_infotainment_groesse" step={0.1} />
			<BooleanField source="display_fahrgast_vorhanden" />
			<NumberField source="display_fahrgast_groesse" step={0.1} />
			<BooleanField source="videoueberwachung_vorhanden" />
			<BooleanField source="akustisches_ansagesystem_vorhanden" />
			<BooleanField source="fgzs_vorhanden" />
			<NumberField source="fgzs_anzahl_tueren" step={1} />
        </SimpleShowLayout>
    </Show>)
}

export const VehicleCreate = () => {
    return (
    <Create>
        <VehicleDetail/>
    </Create>)
}

export const SubconcessionairesList = () => {
    return (
    <List>
        <Datagrid>
            <TextField source="name_de" />
            <TextField source="name_it" />
        </Datagrid>
    </List>)
}

const SubconcessionaireDetail = () => {
    <SimpleForm>
        <TextInput source="name_de" />
        <TextInput source="name_it" />
    </SimpleForm>
}

export const SubconcessionaireEdit = () => {
    <Edit>
        <SubconcessionaireDetail/>
    </Edit>
}

export const SubconcessionaireCreate = () => {
    <Create>
        <SubconcessionaireDetail/>
    </Create>
}

export const OperatorsList = () => {
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="short_name" />
        </Datagrid>
    </List>
}
