export default {
	resources: {
		'vehicles.vehicle': {
			name: 'Fahrzeug |||| Fahrzeuge',
			fields: {
				umweltklasse_const_id: 'Umweltklasse',
				treibstoff_const_id: 'Treibstoff',
				betreiber_id: 'Betreiber',
				subkonzessionaer: 'Subkonzessionär',
				matrikelnummer: 'Matrikelnummer',
				kennzeichen: 'Kennzeichen',
				datum_erstzulassung: 'Datum Erstzulassung',
				hersteller: 'Hersteller',
				rahmennummer: 'Rahmennummer',
				klasse_ministerialdekret_const_id: 'Klasse Ministerialdekret',
				motorleistung: 'Motorleistung [kW]',
				partikelfiler_vorhanden: 'Partikelfilter vorhanden',
				klimaanlage_vorhanden: 'Klimaanlage vorhanden',
				zugang_behinderte: 'Zugang für mobilitätseingeschränkte Personen (Rampe/Lift)',
				halterung_ski_rad: 'Halterungen für Ski/Rad',
				layout_farbe_const_id: 'Layout und Farbe',
				fahrzeuglaenge: 'Fahrzeuglänge',
				anzahl_tueren: 'Anzahl Türen',
				plaetze_gesamt: 'Anzahl Plätze (gesamt)',
				sitzplaetze: 'Anzahl Sitzplätze',
				stehplaetze: 'Anzahl Stehplätze',
				plaetze_rollstuhl: 'Plätze für Rollstuhl',
				plaetze_kinderwagen: 'Plätze für Kinderwagen',
				niederflur_const_id: 'Niederflur/Hochflur',
				display_vorne_vorhanden: 'Display vorhanden (vorne)',
				display_vorne_typ_const_id: 'Displaytyp (vorne)',
				display_vorne_aufloesung: 'Displayauflösung (vorne) [Pixel]',
				display_hinten_vorhanden: 'Display vorhanden (hinten)',
				display_hinten_typ_const_id: 'Displaytyp (hinten)',
				display_hinten_aufloesung: 'Displayauflösung (hinten) [Pixel]',
				display_seitlich_vorhanden: 'Display vorhanden (seitlich)',
				display_seitlich_typ_const_id: 'Displaytyp (seitlich)',
				display_seitlich_aufloesung: 'Displayauflösung (seitlich) [Pixel]',
				display_infotainment_vorhanden: 'Infotainmentdisplay vorhanden',
				display_infotainment_groesse: 'Größe Infotainmentdisplay [Zoll]',
				display_fahrgast_vorhanden: 'Display für Fahrgastinformation vorhanden',
				display_fahrgast_groesse: 'Größe Fahrgastinformationsdisplay [Zoll]',
				videoueberwachung_vorhanden: 'Videoüberwachung vorhanden',
				akustisches_ansagesystem_vorhanden: 'Akustisches Ansagesystem vorhanden',
				fgzs_vorhanden: 'Fahrgastzählsystem vorhanden',
				fgzs_anzahl_tueren: 'Türen mit Fahrgastzählsystem',
				has_ticketing_old: 'Ausstattung Ticketing-Altsystem',
				has_ticketing_new: 'Ausstattung Ticketing-Neusystem',
				last_update_timestamp: 'aktualisiert am',
				sta_matrikelnummer: 'ITCS-Matrikelnummer'
			}
		},
		'vehicles.subconcessionaire': {
			name: 'Subkonzessionär |||| Subkonzessionäre',
			fields: {
				name_de: 'Name (de)',
				name_it: 'Name (it)'
			}
		},
		'vehicles.constants': {
			name: 'Konstante |||| Konstanten'
		},
		'vehicles.operators_vw':{
			name: 'Betreiber |||| Betreiber',
			fields: {
				identifier: 'Id',
				name: 'Name',
				short_name: 'Kürzel'
			}
		},
		'administration.users': {
			name: 'Benutzer |||| Benutzer',
			fields: {
				username: 'Benutzername',
				email: 'Email',
				role: 'Rolle',
			}
		},
		'administration.user_contexts': {
			name: 'User-Kontext |||| User-Kontexte',
			fields: {
				context_id: 'Id'
			}
		},
		'administration.user_delegations': {
			name: 'Delegierung |||| Delegierungen',
			fields: {
				delegation_type: 'Typ',
				delegation_identifier: 'Id'
			}
		},
		'operators_portal.line_events_vw': {
			name: 'Begründung |||| Begründungen',
			updated: "Begründung wurde aktualisiert |||| %{smart_count} Begründungen wurden aktualisiert",
			fields: {
				type_const_id: 'Typ',
				reason_approved: 'bestätigt',
				reason_const_id: 'Begründung',
				reason_description: 'zusätzliche Beschreibung',
				operator_id: 'Betreiber',
				operator_id_displayed: 'Betreiber',
				line_public_code: 'Linie',
				start_stop_place: 'Starthaltestelle',
				start_stop_place_de: 'Starthaltestelle',
				start_stop_place_it: 'Starthaltestelle',
				end_stop_place: 'Endhaltestelle',
				end_stop_place_de: 'Endhaltestelle',
				end_stop_place_it: 'Endhaltestelle',
				start_time: 'Uhrzeit',
				date: 'Datum',
				details: 'Details',
				reason_approved_description: 'evtl. Begründung',
				is_locked: 'gesperrt',
				vehicle_id: 'Fahrzeug',
				line_suffix: 'Gebiet',
				rideid: 'Fahrtnummer',
				distance_planned: 'Strecke [m]',
				distance_done: 'durchgeführte Strecke [m]',
				distance_reinforced: 'Distanz verstärkt [m]',
				time_reinforced: 'Fahrzeit verstärkt [min]',
				substitute_journey: 'fristgerechte Ersatzfahrt wurde durchgeführt',
				lot_identifier: 'Los',
				start_time_without_date: 'Abfahrtszeit',
				end_time_without_date: 'Ankunftszeit'
			},
			msg: {
				rt_no_realtime: 'keine realen Echtzeitdaten, nur plausible Schätzwerte'
			}
		},
		'operators_portal.rt_events_vw': {
			name: 'Fahrt |||| Fahrten',
			fields: {
				operator_id: 'Betreiber',
				line_public_code: 'Linie',
				start_stop_place: 'Starthaltestelle',
				start_stop_place_de: 'Starthaltestelle',
				start_stop_place_it: 'Starthaltestelle',
				end_stop_place: 'Endhaltestelle',
				end_stop_place_de: 'Endhaltestelle',
				end_stop_place_it: 'Endhaltestelle',
				date: 'Datum',
				details: 'Details',
				vehicle_id: 'Fahrzeug',
				line_suffix: 'Gebiet',
				rideid: 'Fahrtnummer'
			}
		},
		'operators_portal.manual_data_uploads': {
			name: 'VDV454 Upload |||| VDV454 Uploads',
			fields: {
				operator_id: 'Betreiber',
				file_name: 'Dateiname',
				is_valid: 'ok'
			}
		},
		'operators_portal.strike': {
			name: 'Streik |||| Streiks',
			fields: {
				operator_id: 'Betreiber',
				begin_ts: 'Beginn',
				end_ts: 'Ende'
			}
		},
		'operators_portal.unplanned_changes': {
			name: 'Ungeplante Fahrplanänderung |||| Ungepl. Fahrplanänderungen',
			fields: {
				operator_id: 'Betreiber',
				line_public_code: 'Linie',
				begin_ts: 'Beginn',
				end_ts: 'Ende',
				description: 'Anmerkung',
				plus_km_l72h: 'Mehr-km in den ersten 72h',
				plus_km_g72h: 'Mehr-km nach den ersten 72h',
				minus_km_g72h: 'Minder-km nach den ersten 72h'
			}
		},
		'operators_portal.outage_intervals': {
			name: 'Ausfall SB Ritten |||| Ausfälle SB Ritten',
			fields: {
				operator_id: 'Betreiber',
				line_public_code: 'Linie',
				begin_ts: 'Beginn',
				end_ts: 'Ende',
				reason_const_id: 'Begründung',
				reason_description: 'Anmerkung'
			}
		},
		'operators_portal.manual_passenger_counting': {
			name: 'Fahrgastzählung |||| Fahrgastzählungen',
			fields: {
				operator_id: 'Betreiber',
				line_public_code: 'Linie',
				date: 'Datum',
				counting_inbound: 'Zählung (Talstation/Hinfahrt)',
				counting_outbound: 'Zählung (Bergstation/Rückfahrt)',
				description: 'Anmerkung'
			}
		},
		'operators_portal.reports_vw': {
			name: 'Report |||| Reports',
			updated: "Report wurde aktualisiert |||| %{smart_count} Reports wurden aktualisiert",
			fields: {
				operator_ids: 'Betreiber',
				type_id: 'Typ',
				date_from: 'gültig von',
				date_to: 'gültig bis',
				timestamp: 'aktualisiert am',
				is_official: 'endgültig',
				lock_attachments: 'Lock A10'
			}
		},
		'operators_portal.extra_bus_information_vw': {
			name: 'Fahrplanänderung |||| Fahrplanänderungen',
			updated: "Fahrplanänderung wurde aktualisiert |||| %{smart_count} Fahrplanänderung wurden aktualisiert",
			fields: {
				schedule_year: 'Fahrplan',
				operator_id: 'Betreiber',
				operator_id_displayed: 'Betreiber',
				line_public_code: 'Linie',
				start_time: 'Startzeit',
				start_stop_place: 'Starthaltestelle',
				first_date: 'gültig von',
				last_date: 'gültig bis',
				verkehrstage: 'Verkehrstage',
				distance_m: 'Distanz',
				journey_time_min: 'Fahrzeit',
				requires_extra_bus: 'Zusatzbus / Buseinsparung'
			}
		},
		'operators_portal.report_attachments': {
			name: 'Abgaben |||| Abgaben'
		},
		'operators_portal.segment_changes_log': {
			name: 'Teilstreckenkorrektur |||| Teilstreckenkorrekturen',
			fields: {
				service_link_ref: 'Teilstrecke',
				description: 'Beschreibung',
				last_update_timestamp: 'letzte Änderung'
			}
		},
		'operators_portal.service_link_mvw': {
			name: 'Teilstrecke |||| Teilstrecken',
			fields: {
				q: 'Teilstrecke'
			}
		},
		'operators_portal.sasa_journey_codes_vw': {
			name: 'ITCS code |||| ITCS codes',
			fields: {
				line_public_code: 'Linie',
				date: 'Datum',
				lot_identifier: 'Los',
				departure_time: 'Startzeit',
				arrival_time: 'Ankunftszeit',
				start_global_id: 'globalID (Start)',
				start_stop_place_de: 'Starthaltestelle',
				start_stop_place_it: 'Starthaltestelle',
				end_global_id: 'globalID (Ankunft)',
				line_private_code: 'Private code (Linie)',
				journey_private_code: 'Private code (Fahrt)',
				itcs_code: 'ITCS code',
				linien_kursnummer: 'Linien-Kursnummer',
				kursnummer: 'Kursnummer'
			}
		},
		'operators_portal.segment_changes': {
			name: 'Teilstreckenänderung |||| Teilstreckenänderungen',
			updated: "Teilstreckenänderung aktualisiert |||| %{smart_count} Teilstreckenänderungen aktualisiert",
			fields: {
				schedule_year: 'Fahrplan',
				schedule_year_de: 'Fahrplan',
				from_place_name_de: 'Abfahrt',
				to_place_name_de: 'Ankuft',
				operational_context_ref: 'Betriebszweig',
				operational_context_name_de: 'Betriebszweig',
				distance_jd: 'Jahresdekret [m]',
				distance_cns: 'Sollfahrplan [m]',
				last_data_update: 'Letzte Aktualisierung der Strecke',
				check_operator: 'Check Betreiber',
				check_sta: 'Check STA'
			}
		},
		'operators_portal.itcs_efa': {
			name: 'ITCS Einzalfahrtanalyse |||| ITCS Einzalfahrtanalyse',
			fields: {
				lot: 'Los',
				datum: 'Datum',
				datum_von: 'Datum von',
				datum_bis: 'Datum bis',
				source: 'Quelle',
				operator_id: 'Betreiber',
				operator_id_displayed: 'Betreiber',
				line: 'Line',
				fahrt: 'Fahrt',
				line_public_code: 'Linie',
				line_suffix: 'Gebiet',
				abfahrtszeit_von: 'von',
				abfahrtszeit_bis: 'bis',
				abfahrtszeit_soll: 'Abfahrtszeit Soll',
				abfahrtszeit_ist: 'Abfahrtszeit Ist',
				fahrzeugnummer: 'Fahrzeugnummer',
				start: 'Start GlobalID',
				start_stop_place_name: 'Starthaltestelle',
				start_stop_place_name_de: 'Starthaltestelle',
				start_stop_place_name_it: 'Starthaltestelle',
				ziel: 'Ziel GlobalID',
				ziel_stop_place_name: 'Endhaltestelle',
				ziel_stop_place_name_de: 'Endhaltestelle',
				ziel_stop_place_name_it: 'Endhaltestelle',
				basisversion: 'Basisversion',
				richtung: 'Richtung',
				verlaufsnummer: 'Verlaufsnummer',
				haltepunkt: 'Haltepunkt',
				haltepunkt_stop_place_name: 'Name Haltepunkt',
				haltepunkt_stop_place_name_de: 'Name Haltepunkt',
				haltepunkt_stop_place_name_it: 'Name Haltepunkt',
				soll_an: 'Soll An',
				soll_ab: 'Soll Ab',
				ist_an: 'Ist An',
				ist_ab: 'Ist Ab',
				delta_ab: 'Abw. Ab',
				delta_an: 'Abw. An',
				st_aend_urs: 'Änderungsursache',
				fahrgastwechselzeit: 'Fahrgastwechselzeit',
				error_class: 'Fehlerklasse'
			},
			errorClasses: {
				ERR_1: 'Verfrühung bei Start',
				ERR_2: 'Teilausfall',
				ERR_3: 'Verfrühter/Verspäteter Fahrtverlauf',
				ERR_4: 'Fahrplan nicht eingehalten',
				ERR_5: 'Ist-Zeit immer gleich'
			}
		},
	},
	localizedName: 'name_de',
	localizedNameDefaultDe: 'name',
	dropzoneText: 'Zum Hochladen Datei hineinziehen oder hier klicken, um eine Datei auszuwählen.',
	booleanAndUndefined: {
		yes: 'ja',
		no: 'nein',
		undefined: 'nicht definiert'
	},
	filter_from: 'von',
	filter_to: 'bis',
	lineSuffix: {
		bz: 'Bozen',
		me: 'Meran',
		empty: 'außerstädtisch'
	},
	language: {
		language: 'Sprache',
		setLanguage: 'Sprache wählen'
	},
	auth:{
		forgotPassword: 'Password vergessen?',
		password_expired: 'Das Passwort ist abgelaufen. Klicken Sie "Password vergessen?" und legen Sie ein neues Passwort fest.'
	},
	administration: {
		addContext: 'Kontext hinzufügen',
		addDelegation: 'Delegierung hinzufügen',
		visibleContexts: 'Sichtbare Kontexte',
		activeDelegations: 'Aktive Delegierungen'
	},
	operators_portal: {
		attachments: 'Anhänge',
		report_attachments: 'Anlage A10',
		bulkAddReasonAction: 'Rechtfertigen'
	},
	localizedFields: {
		start_stop_place: 'start_stop_place_de',
		end_stop_place: 'end_stop_place_de',
		stop_place_name: 'stop_place_name_de',
		schedule_year: 'schedule_year_de',
		from_place_name: 'from_place_name_de',
		to_place_name: 'to_place_name_de',
		operational_context_name: 'operational_context_name_de',
		name: 'name_de',
		start_stop_place_name: 'start_stop_place_name_de',
		ziel_stop_place_name: 'ziel_stop_place_name_de',
		haltepunkt_stop_place_name: 'haltepunkt_stop_place_name_de',
	}
}
