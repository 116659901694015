import postgrestRestProvider from './ra-data-postgrest';
import {fetchUtils, useTranslate} from 'react-admin';
var moment = require('moment');

// Hint: Do not use a value that contains like (eg. ilike or like), because
const OPERATOR_LIKE = 'OP_SIT_LI'

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem("token");
    if (token && token.length > 0) {
        options.headers.set("Authorization", `Bearer ${token}`);
    }
    //workaround that allows to filter with wildcards '%'
    url = url.replaceAll(OPERATOR_LIKE, "like")
    return fetchUtils.fetchJson(url, options);
};

const extractResource = (resource) => (resource.split('.').pop());

const removeUnchangedAttributes = (data, previousData) => {
    for (var key in data) {
        if (JSON.stringify(data[key]) === JSON.stringify(previousData[key])) {
            delete data[key];
        }
    }
    return data;
}

const convertFilters = filter => {
    if(filter !== null && filter !== undefined) {
        let newFilter = {};
        Object.keys(filter).map(key => {
            let newKey = key;
            let splitKey = key.split('@');
            let column = splitKey[0];
            let operation = splitKey.length === 2 ? splitKey[1] : 'eq';
            switch (typeof filter[key]) {
                case "string":
                    if(filter[key] !== 'undefined'
                        && filter[key].includes('*')) {
                        // workaround that allows to filter with wildcards '%'.
                        newKey = column + "@" + OPERATOR_LIKE
                    }
                    if(operation === 'cs') {
                        filter[key] = `{${filter[key]}}`
                    }
                    break;

                case "boolean":
                    newKey = column + "@is"
                    break;

                case "number":
                    if(Array.isArray(filter[key])) {
                        newKey = column
                    }
                    break;

                case "undefined":
                    newKey = column + "@is"
                    filter[key] = 'null'
                    break;

                case "object":
                    if(Array.isArray(filter[key])) {
                        newKey = column + "@in"
                        filter[key] = `(${filter[key]})`
                    }
                    break;

                default:
                    newKey = column + "@is"
                    filter[key] = 'null'
                    break;
            }
            newFilter[newKey] = filter[key];
            return filter[key]
        });
        filter = newFilter;
    }
    return filter;
}

const dataProvider = (apiUrl): DataProvider => {
    const postgrestClient = postgrestRestProvider(apiUrl, httpClient)

    return {

        getList: (resource, params) => {
            if(resource === 'operators_portal.manual_data_uploads') {
                params = {
                    ...params,
                    meta: {
                        columns: ['id', 'operator_id', 'file_name', 'is_valid']
                    }
                }
            }
            if(resource === 'operators_portal.reports_vw') {
                params = {
                    ...params,
                    meta: {
                        columns: ['id', 'operator_ids', 'type_id', 'date_from', 'date_to', 'timestamp', 'filename', 'official_timestamp', 'is_official', 'lock_attachments']
                    }
                }
            }
            if(resource === 'operators_portal.extra_bus_information_vw') {
                params = {
                    ...params,
                    sort: {
                        field: params.sort.field,
                        order: `${params.sort.order.toLowerCase()},start_time.asc`
                    }
                }
            }
            if(resource === 'operators_portal.reports_vw') {
                params = {
                    ...params,
                    sort: {
                        field: params.sort.field,
                        order: `${params.sort.order.toLowerCase()},type_id.asc`
                    }
                }
            }
            if(resource === 'operators_portal.line_events_vw') {
                if(params.filter['start_time_without_date']) {
                    params.filter['start_time_without_date'] =
                        moment(params.filter['start_time_without_date']).format('HH:mm');
                }
                if(params.filter['end_time_without_date']) {
                    params.filter['end_time_without_date'] =
                        moment(params.filter['end_time_without_date']).format('HH:mm');
                }
            }
            if(resource === 'operators_portal.segment_changes') {
                params = {
                    ...params,
                    sort: {
                        field: params.sort.field,
                        order: `${params.sort.order.toLowerCase()},distance_cns.asc`
                    }
                }
            }
            if(resource === 'operators_portal.itcs_efa') {
                if(params.filter['abfahrtszeit_without_date@gte']) {
                    params.filter['abfahrtszeit_without_date@gte'] =
                        moment(params.filter['abfahrtszeit_without_date@gte']).format('HH:mm')+':00';
                }
                if(params.filter['abfahrtszeit_without_date@lte']) {
                    params.filter['abfahrtszeit_without_date@lte'] =
                        moment(params.filter['abfahrtszeit_without_date@lte']).format('HH:mm')+':59';
                }
                params = {
                    ...params,
                    sort: {
                        field: params.sort.field,
                        order: `${params.sort.order.toLowerCase()},abfahrtszeit_without_date.asc,fahrt.asc,verlaufsnummer.asc`
                    },
                    meta: {
                        columns: ['id', 'datum', 'abfahrtszeit_without_date', 'source', 'operator_id_displayed', 'line_public_code', 'line_suffix', 'lot_id', 'fahrt', 'kurs', 'abfahrtszeit_soll', 'abfahrtszeit_ist', 'fahrzeugnummer', 'start', 'start_stop_place_name_de', 'start_stop_place_name_it', 'ziel', 'ziel_stop_place_name_de', 'ziel_stop_place_name_it', 'basisversion', 'richtung', 'verlaufsnummer', 'haltepunkt', 'haltepunkt_stop_place_name_de', 'haltepunkt_stop_place_name_it', 'haltezeit', 'soll_an', 'ist_an', 'delta_an', 'soll_ab', 'ist_ab', 'delta_ab', 'st_aend_urs', 'fahrgastwechselzeit', 'error_class']
                    }
                }
            }
            params.filter = convertFilters(params.filter)
            //ugly workaround for the export limit of 1000 records
            if (params.pagination && params.pagination.page === 1 && params.pagination.perPage === 1000) {
                params.pagination.perPage = 100000;
            }
            return postgrestClient.getList(extractResource(resource), params);
        },

        getOne: (resource, params) => {
            return postgrestClient.getOne(extractResource(resource), params);
        },

        getMany: (resource, params) => {
            return postgrestClient.getMany(extractResource(resource), params);
        },

        getManyReference: (resource, params) => {
            if(resource === 'operators_portal.attachments') {
                params = {
                    ...params,
                    meta: {
                        columns: ['id', 'line_event_id', 'file_name']
                    }
                }
            }
            if(resource === 'operators_portal.report_attachments') {
                params = {
                    ...params,
                    meta: {
                        columns: ['id', 'report_id', 'file_name', 'creation_ts']
                    }
                }
            }
            params.filter = convertFilters(params.filter)
            return postgrestClient.getManyReference(extractResource(resource), params);
        },

        update: (resource, params) => {
            if(resource === 'operators_portal.strike' || resource === 'operators_portal.outage_intervals' || resource === 'operators_portal.unplanned_changes') {
                if(params.data && params.data.begin_ts){
                    params.data.begin_ts = moment(params.data.begin_ts).format('YYYY-MM-DDTHH:mm');
                }
                if(params.data && params.data.end_ts){
                    params.data.end_ts = moment(params.data.end_ts).format('YYYY-MM-DDTHH:mm');
                }
            }
            params.data = removeUnchangedAttributes(params.data, params.previousData);
            return postgrestClient.update(extractResource(resource), params);
        },

        updateMany: (resource, params) => {
            return postgrestClient.updateMany(extractResource(resource), params);
        },

        create: (resource, params) => {
            if(resource === 'operators_portal.strike' || resource === 'operators_portal.outage_intervals' || resource === 'operators_portal.unplanned_changes') {
                if(params.data && params.data.begin_ts){
                    params.data.begin_ts = moment(params.data.begin_ts).format('YYYY-MM-DDTHH:mm');
                }
                if(params.data && params.data.end_ts){
                    params.data.end_ts = moment(params.data.end_ts).format('YYYY-MM-DDTHH:mm');
                }
            }
            return postgrestClient.create(extractResource(resource), params);
        },

        delete: (resource, params) => {
            return postgrestClient.delete(extractResource(resource), params);
        },

        deleteMany: (resource, params) => {
            return postgrestClient.deleteMany(extractResource(resource), params);
        },
    }
}

export default dataProvider
