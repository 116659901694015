import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
    Form,
    required,
    TextInput,
    useTranslate,
    useLogin,
    useNotify,
} from 'react-admin';

import Box from '@mui/material/Box';

const SitLogin = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();

    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(
            auth,
            location.state ? location.state.nextPathname : '/'
        ).catch((error: Error) => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                {
                    type: 'error',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                    ? error.message
                                    : undefined,
                    },
                }
            );
        });
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    height: '1px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    background: 'url(./suedtirolmobil.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto',
                    padding: '10px',
                    backgroundOrigin: 'content-box'
                }}
            >
                <Card sx={{minWidth: 300, marginTop: '6em'}}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar sx={{bgcolor: theme => theme.palette.secondary[500]}}>
                            <LockIcon/>
                        </Avatar>
                    </Box>
                    <Box sx={{padding: '0 1em 1em 1em'}}>
                        <Box sx={{marginTop: '1em'}}>
                            <TextInput
                                autoFocus
                                source="username"
                                label={translate('ra.auth.username')}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                        <Box sx={{marginTop: '1em'}}>
                            <TextInput
                                source="password"
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <CardActions sx={{padding: '0 1em 1em 1em'}}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2}/>
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                    <CardActions sx={{padding: '0 1em 1em 1em'}}>
                        <Button
                            disabled={loading}
                            href="../pwm"
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2}/>
                            )}
                            {translate('auth.forgotPassword')}
                        </Button>
                    </CardActions>
                    <CardActions sx={{padding: '0 1em 1em 1em'}}>
                        <span style={{fontSize: '0.75rem', paddingLeft: '8px'}}>
                            Support:
                        <Button
                            disabled={loading}
                            href="mailto:operators-portal@sta.bz.it"
                            size="small"
                        >
                            <span style={{
                                textTransform: 'lowercase',
                                fontSize: '0.75rem'
                            }}>operators-portal@sta.bz.it</span>
                        </Button>
                            </span>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

SitLogin.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default SitLogin;

interface
FormValues
{
    username ? : string;
    password ? : string;
}
