import React, { useState  } from "react";
import { useRefresh, useNotify, useUpdateMany, useRecordContext, useResourceContext, useTranslate } from "react-admin";
import { Checkbox } from '@mui/material';

const ListCheckboxInput = props => {
    const { source } = props;
    const record = useRecordContext();
    const refresh = useRefresh();
    const resource = useResourceContext();
    const notify = useNotify();
    const [updateMany, { isLoading }] = useUpdateMany();
    const [checkboxChecked, setCheckboxChecked] = useState(record[source]);
    const handleChange = e => {
        setCheckboxChecked(e.target.checked);
        updateMany(
            resource,
            { ids: [record.id], data: { [source]: e.target.checked }, meta: {} },
            {
                onSuccess: () => {
                    notify(`resources.${resource}.updated`, { messageArgs: { smart_count: 1 } });
                    refresh();
                },
                onError: error => {
                    notify('Error', { type: 'error' })
                },
                mutationMode: 'pessimistic'
            }
        );
    }
    return (<Checkbox disabled={isLoading} id={record.id} checked={checkboxChecked} onChange={handleChange} size="small"/>);
}

export default ListCheckboxInput
