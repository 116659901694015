"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrderBy = exports.getKeyData = exports.getQuery = exports.isCompoundKey = exports.dataWithId = exports.encodeId = exports.decodeId = exports.getPrimaryKey = exports.parseFilters = void 0;
/**
 * List of all possible operators in PostgRest
 * (https://postgrest.org/en/stable/api.html#operators)
 */
var postgrestOperators = [
    'eq',
    'gt',
    'gte',
    'lt',
    'lte',
    'neq',
    'like',
    'ilike',
    'match',
    'imatch',
    'in',
    'is',
    'fts',
    'plfts',
    'phfts',
    'wfts',
    'cs',
    'cd',
    'ov',
    'sl',
    'sr',
    'nxr',
    'nxl',
    'adj',
    'not',
    'or',
    'and',
];
var parseFilters = function (params, defaultListOp) {
    var filter = params.filter, _a = params.meta, meta = _a === void 0 ? {} : _a;
    var result = {};
    result.filter = {};
    Object.keys(filter).forEach(function (key) {
        // key: the name of the object key
        var splitKey = key.split('@');
        var operation = splitKey.length == 2
            ? splitKey[1]
            : defaultListOp;
        var values;
        if (['like', 'ilike'].includes(operation)) {
            // we split the search term in words
            values = filter[key].trim().split(/\s+/);
        }
        else {
            values = [filter[key]];
        }
        values.forEach(function (value) {
            // if operator is intentionally blank, rpc syntax
            var op = operation.includes('like')
                ? "".concat(operation, ".*").concat(value, "*")
                : operation.length == 0
                    ? "".concat(value)
                    : "".concat(operation, ".").concat(value);
            if (result.filter[splitKey[0]] === undefined) {
                // first operator for the key, we add it to the dict
                result.filter[splitKey[0]] = op;
            }
            else {
                if (!Array.isArray(result[splitKey[0]])) {
                    // second operator, we transform to an array
                    result.filter[splitKey[0]] = [
                        result.filter[splitKey[0]],
                        op,
                    ];
                }
                else {
                    // third and subsequent, we add to array
                    result.filter[splitKey[0]].push(op);
                }
            }
        });
    });
    if (meta === null || meta === void 0 ? void 0 : meta.columns) {
        result.select = Array.isArray(meta.columns)
            ? meta.columns.join(',')
            : meta.columns;
    }
    return result;
};
exports.parseFilters = parseFilters;
var getPrimaryKey = function (resource, primaryKeys) {
    return primaryKeys.get(resource) || ['id'];
};
exports.getPrimaryKey = getPrimaryKey;
var decodeId = function (id, primaryKey) {
    if ((0, exports.isCompoundKey)(primaryKey)) {
        return JSON.parse(id.toString());
    }
    else {
        return [id.toString()];
    }
};
exports.decodeId = decodeId;
var encodeId = function (data, primaryKey) {
    if ((0, exports.isCompoundKey)(primaryKey)) {
        return JSON.stringify(primaryKey.map(function (key) { return data[key]; }));
    }
    else {
        return data[primaryKey[0]];
    }
};
exports.encodeId = encodeId;
var dataWithId = function (data, primaryKey) {
    if (JSON.stringify(primaryKey) === JSON.stringify(['id'])) {
        return data;
    }
    return Object.assign(data, {
        id: (0, exports.encodeId)(data, primaryKey),
    });
};
exports.dataWithId = dataWithId;
var isCompoundKey = function (primaryKey) {
    return primaryKey.length > 1;
};
exports.isCompoundKey = isCompoundKey;
var getQuery = function (primaryKey, ids, resource, meta) {
    var _a, _b;
    if (meta === void 0) { meta = null; }
    var result = {};
    if (Array.isArray(ids) && ids.length > 1) {
        // no standardized query with multiple ids possible for rpc endpoints which are api-exposed database functions
        if (resource.startsWith('rpc/')) {
            console.error("PostgREST's rpc endpoints are not intended to be handled as views. Therefore, no query generation for multiple key values implemented!");
            return;
        }
        if ((0, exports.isCompoundKey)(primaryKey)) {
            result = {
                or: "(".concat(ids.map(function (id) {
                    var primaryKeyParams = (0, exports.decodeId)(id, primaryKey);
                    return "and(".concat(primaryKey
                        .map(function (key, i) { return "".concat(key, ".eq.").concat(primaryKeyParams[i]); })
                        .join(','), ")");
                }), ")"),
            };
        }
        else {
            result = (_a = {},
                _a[primaryKey[0]] = "in.(".concat(ids.join(','), ")"),
                _a);
        }
    }
    else {
        // if ids is one Identifier
        var id = ids.toString();
        var primaryKeyParams_1 = (0, exports.decodeId)(id, primaryKey);
        if ((0, exports.isCompoundKey)(primaryKey)) {
            if (resource.startsWith('rpc/')) {
                result = {};
                primaryKey.map(function (key, i) {
                    return (result[key] = "".concat(primaryKeyParams_1[i]));
                });
            }
            else {
                result = {
                    and: "(".concat(primaryKey.map(function (key, i) {
                        return "".concat(key, ".eq.").concat(primaryKeyParams_1[i]);
                    }), ")"),
                };
            }
        }
        else {
            result = (_b = {},
                _b[primaryKey[0]] = "eq.".concat(id),
                _b);
        }
    }
    if (meta && meta.columns) {
        result.select = Array.isArray(meta.columns)
            ? meta.columns.join(',')
            : meta.columns;
    }
    return result;
};
exports.getQuery = getQuery;
var getKeyData = function (primaryKey, data) {
    var _a;
    if ((0, exports.isCompoundKey)(primaryKey)) {
        return primaryKey.reduce(function (keyData, key) {
            var _a;
            return (__assign(__assign({}, keyData), (_a = {}, _a[key] = data[key], _a)));
        }, {});
    }
    else {
        return _a = {}, _a[primaryKey[0]] = data[primaryKey[0]], _a;
    }
};
exports.getKeyData = getKeyData;
var getOrderBy = function (field, order, primaryKey) {
    if (field == 'id') {
        return primaryKey.map(function (key) { return "".concat(key, ".").concat(order.toLowerCase()); }).join(',');
    }
    else {
        return "".concat(field, ".").concat(order.toLowerCase());
    }
};
exports.getOrderBy = getOrderBy;
