import * as React from 'react';
import {Layout, useRemoveItemsFromStore} from 'react-admin';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, CircularProgress, styled} from '@mui/material';
import {AppBar, Menu, Sidebar} from 'react-admin';


const ContentAsside = styled("div")(({theme}) => ({
    display: "flex",
    flexGrow: 1,
}));

const Content = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
}));

const ContentMain = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1000,
}));

const SupportFooter = styled("span")(({theme}) => ({
    display: "flex",
    flexGrow: 0,
    alignItems: "center",
    fontSize: '0.75rem'
}));

const MyAppBar = (children) => (
    <Content>
        <ContentMain>
            {children}
        </ContentMain>
        <ContentAsside/>
        <SupportFooter>Support:
            <Button
                href="mailto:operators-portal@sta.bz.it"
                size="small"
            >
                <span style={{
                    textTransform: 'lowercase',
                    fontSize: '0.75rem'
                }}>operators-portal@sta.bz.it</span>
            </Button></SupportFooter>
    </Content>
);

const MyLayout = props => <Layout
    {...props}
    children={MyAppBar(props.children)}
/>;

export default MyLayout
