import React, { Fragment, useState } from 'react';
import { useTranslate, useRefresh, useResourceContext, useGetList, useDataProvider, useUpdateMany, useListContext, useNotify, useUnselectAll } from 'react-admin';
import CheckIcon from '@mui/icons-material/Done';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';

const BulkAddReasonAction = props => {
    const { selectedIds } = useListContext();
    const resource = useResourceContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [selectedReason, setSelectedReason] = useState('')
    const [distanceDone, setDistanceDone] = useState(0)
    const [reasonDescription, setReasonDescription] = useState('')
    const [updateMany, { isLoading }] = useUpdateMany();
    const unselectAll = useUnselectAll(resource);

    const reasonTypes = useGetList('operators_portal.constants', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: translate('localizedName'), order: 'ASC' },
            filter: { category: 'reason_type' },
        });

    const handleClick = () => {
        updateMany(
            resource,
            {
                ids: selectedIds,
                data: {
                    reason_description: reasonDescription,
                    reason_const_id: selectedReason === '' && reasonTypes.isSuccess? reasonTypes.data[0].id: selectedReason,
                    distance_done: distanceDone
                }
            },
            {
                onSuccess: () => {
                    notify(`resources.${resource}.updated`, { messageArgs: { smart_count: 1 } });
                    refresh();
                    unselectAll();
                },
                onError: error => {
                    notify('Error', { type: 'error' })
                },
                mutationMode: 'pessimistic'
            }
        );
    };

    return (
        <Fragment>
            <FormControl size="small" margin="none">
                <InputLabel id="demo-simple-select-label">
                    {translate('resources.operators_portal.line_events_vw.fields.reason_const_id')}
                </InputLabel>
                <Select
                    value={selectedReason === '' && reasonTypes.isSuccess? reasonTypes.data[0].id: selectedReason}
                    label={translate('resources.operators_portal.line_events_vw.fields.reason_const_id')}
                    onChange={event => setSelectedReason(event.target.value)}
                    size="small"
                    margin="none">
                    {(reasonTypes.isSuccess? reasonTypes.data: []).map(reasonType => (
                        <MenuItem key={reasonType.code} value={reasonType.id}>
                            {reasonType[translate('localizedName')]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="small" margin="none">
                <TextField value={ distanceDone }
                        type="number"
                        label={translate('resources.operators_portal.line_events_vw.fields.distance_done')}
                        onChange={event => setDistanceDone(event.target.value)}
                        size="small"
                        margin="none"
                        style={{width: 180}} />
            </FormControl>
            <FormControl size="small" margin="none">
                <TextField value={ reasonDescription }
                        label={translate('resources.operators_portal.line_events_vw.fields.reason_description')}
                        onChange={event => setReasonDescription(event.target.value)}
                        size="small"
                        margin="none"
                        style={{width: 350}} />
            </FormControl>
            <FormControl>
                <Button disabled={isLoading} onClick={handleClick} variant="outlined" startIcon={<CheckIcon />} onClick={handleClick} margin="normal" size="small">
                    {translate('operators_portal.bulkAddReasonAction')}
                </Button>
            </FormControl>
        </Fragment>
    );
}

export default BulkAddReasonAction;