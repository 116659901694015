// in src/authProvider.ts
import decodeJwt from 'jwt-decode';

// TypeScript users must reference the type: `AuthProvider`
export const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        localStorage.setItem("username", username);
        const request = new Request(process.env.REACT_APP_AUTHENTICATION_SERVICE_URL, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if(response.status === 401) {
                    return response.json()
                        .then(errJson => {
                            if(errJson && errJson.passwordExpired)
                                throw new Error('auth.password_expired');
                            throw new Error('ra.auth.sign_in_error');
                        }).catch(e => {
                            throw e
                        });
                } else if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }
                return response.json();
            })
            .then(({ token, contexts }) => {
                localStorage.setItem('token', token);
                const decodedToken = decodeJwt(token);
                localStorage.setItem('role', decodedToken.role);
                localStorage.setItem('contexts', JSON.stringify(contexts));
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('contexts');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('contexts');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const contexts = JSON.parse(localStorage.getItem('contexts'));
        const role = localStorage.getItem('role');
        return contexts ? Promise.resolve({ contexts: contexts, role: role }) : Promise.reject();
    }
};
