import React from 'react';
import { List, Datagrid, TextField, Labeled, ReferenceField, Edit, SimpleForm, ReferenceInput,
        SelectInput, TextInput, EditButton, Create, ReferenceManyField,
        SingleFieldList, ChipField, DeleteButton, useRecordContext  } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from 'react-admin';
import { parse } from 'query-string';

const UsersFilter = () => {
    return [
        <TextInput source="username" alwaysOn/>,
        <SelectInput source="role" choices={[
            { id: 'sitadmin', name: 'sitadmin' },
            { id: 'operator', name: 'operator' },
            { id: 'monitoring', name: 'monitoring' },
            { id: 'observer', name: 'observer' },
        ]} alwaysOn/>
    ]
}

export const UsersList = () => {
    return (
    <List filters={UsersFilter()}>
        <Datagrid>
            <TextField source="username"/>
            <TextField source="email"/>
            <TextField source="role"/>
            <ReferenceManyField label="administration.visibleContexts" reference="administration.user_contexts" target="user_id" sort={{ field: 'context_id', order: 'ASC' }}>
                <SingleFieldList>
                    <ReferenceField source="context_id" reference="administration.contexts">
                        <ChipField source="identifier"/>
                    </ReferenceField>
                </SingleFieldList>
            </ReferenceManyField>
            <EditButton />
        </Datagrid>
    </List>)
}

const styles = {
    button: {
        marginTop: '1em',
        marginBottom: '1em',
    },
};

const AddNewContextButton = () => {
    const record = useRecordContext();
    return (
    <Button
        sx={styles.button}
        variant="raised"
        component={Link}
        to={{
            pathname: '/administration.user_contexts/create',
            search: `?source=${JSON.stringify({ user_id: record !== undefined? record.id: undefined })}`
        }}
        label="administration.addContext"
    >
        <AddCircleIcon />
    </Button>)
}

const AddNewDelegationButton = () => {
    const record = useRecordContext();
    return (
    <Button
        sx={styles.button}
        variant="raised"
        component={Link}
        to={{
            pathname: '/administration.user_delegations/create',
            search: `?source=${JSON.stringify({ user_id: record !== undefined? record.id: undefined })}`
        }}
        label="administration.addDelegation"
    >
        <AddCircleIcon />
    </Button>)
};

const MyEditButton = () => {
    const record = useRecordContext();
    return (
    <EditButton sx={styles.button} to={{ pathname: `/administration.user_delegations/${record.id}/edit`, search: `?source=${JSON.stringify({ user_id: record !== undefined? record.user_id: undefined })}` }}>
    </EditButton>)
};

const UserDetail = () => {
    const location = useLocation();

    const redirect = location.pathname ? location.pathname : false;

    const data = {id: 1}

    return (<SimpleForm>
        <TextInput source="username" />
        <TextInput source="email" />
        <TextInput source="role" />
        <Labeled sx={{ marginTop: '8px' }}>
            <ReferenceManyField label="administration.visibleContexts" reference="administration.user_contexts" target="user_id" sort={{ field: 'context_id', order: 'ASC' }}>
                <Datagrid bulkActionButtons={false}>
                    <ReferenceField source="context_id" reference="administration.contexts">
                        <TextField source="identifier" />
                    </ReferenceField>
                    <DeleteButton redirect={redirect} />
                </Datagrid>
            </ReferenceManyField>
        </Labeled>
        <AddNewContextButton />
        <Labeled sx={{ marginTop: '8px' }}>
            <ReferenceManyField label="administration.activeDelegations" reference="administration.user_delegations" target="user_id">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="delegation_type" />
                    <TextField source="delegation_identifier" />
                    <MyEditButton/>
                    <DeleteButton redirect={redirect} />
                </Datagrid>
            </ReferenceManyField>
        </Labeled>
        <AddNewDelegationButton/>
    </SimpleForm>);
}

export const UserEdit = () => {
    return (
    <Edit>
        <UserDetail />
    </Edit>)
};

export const UserCreate = () => {
    return (
    <Create>
        <UserDetail />
    </Create>)
};

export const UserContextCreate = ({ hasCreate, hasList, hasEdit, hasShow }) => {
    const location = useLocation();
    let user_id = ''
    const { source: search_source } = parse(location.search);
    if(search_source) {
        const { user_id: user_id_string } = JSON.parse(search_source);
        user_id = user_id_string ? parseInt(user_id_string, 10) : '';
    }

    const redirect = user_id ? `/administration.users/${user_id}/show` : false;

    return (
        <Create>
            <SimpleForm defaultValue={{ user_id: user_id }} redirect={redirect}>
                {
                    user_id
                        ? (<ReferenceField source="user_id" reference="administration.users">
                                <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                                    <TextField source="username" />
                                </Labeled>
                           </ReferenceField>)
                        : (<ReferenceInput source="user_id" reference="administration.users">
                                <SelectInput optionText="username" optionValue="id" />
                           </ReferenceInput>)
                }
                <ReferenceInput source="context_id" reference="administration.contexts">
                    <SelectInput optionText="identifier" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

const UserDelegationDetail = ({ hasCreate, hasList, hasEdit, hasShow }) => {
    const location = useLocation();
    let user_id = ''
    const { source: search_source } = parse(location.search);
    if(search_source) {
        const { user_id: user_id_string } = JSON.parse(search_source);
        user_id = user_id_string ? parseInt(user_id_string, 10) : '';
    }

    const redirect = user_id ? `/administration.users/${user_id}/show` : false;

    return (<SimpleForm defaultValue={{ user_id: user_id }} redirect={redirect}>
        {
            user_id
                ? (<ReferenceField source="user_id" reference="administration.users">
                    <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                        <TextField source="username" />
                    </Labeled>
                </ReferenceField>)
                : (<ReferenceInput source="user_id" reference="administration.users">
                    <SelectInput optionText="username" optionValue="id" />
                </ReferenceInput>)
        }
        <TextInput source="delegation_type" />
        <TextInput source="delegation_identifier" />
    </SimpleForm>);
};

export const UserDelegationEdit = () => {
    return (
    <Edit>
        <UserDelegationDetail/>
    </Edit>)
};

export const UserDelegationCreate = () => {
    return (
    <Create>
        <UserDelegationDetail/>
    </Create>)
};
