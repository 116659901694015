export default {
	resources: {
		'vehicles.vehicle': {
			name: 'Veicolo |||| Veicoli',
			fields: {
				umweltklasse_const_id: 'Classe EURO',
				treibstoff_const_id: 'Carburante',
				betreiber_id: 'Operatore',
				subkonzessionaer_id: 'Subconcessionario',
				matrikelnummer: 'Numero di matricola',
				kennzeichen: 'Targa',
				datum_erstzulassung: 'Data Immatriculazione',
				hersteller: 'Produttore',
				rahmennummer: 'Numero telaio',
				klasse_ministerialdekret_const_id: 'Classe decreto ministeriale',
				motorleistung: 'Potenza [kW]',
				partikelfiler_vorhanden: 'Filtro antiparticolato',
				klimaanlage_vorhanden: 'Condizionatore d\'aria',
				zugang_behinderte: 'Accesso per persone a mobilità ridotta (rampa/ascensore)',
				halterung_ski_rad: 'Staffe per sci/bici',
				layout_farbe_const_id: 'Layout e colore',
				fahrzeuglaenge: 'Lunghezza',
				anzahl_tueren: 'Numero porte',
				plaetze_gesamt: 'Numero posti (totale)',
				sitzplaetze: 'Numero posti a sedere',
				stehplaetze: 'Numero posti in piedi',
				plaetze_rollstuhl: 'Posti per sedia a rotelle',
				plaetze_kinderwagen: 'Posti per passeggini',
				niederflur_const_id: 'Pavimento basso/piano alto',
				display_vorne_vorhanden: 'Display disponibile (fronte)',
				display_vorne_typ_const_id: 'Tipo di display (fronte)',
				display_vorne_aufloesung: 'Risoluzione display (fronte) [pixel]',
				display_hinten_vorhanden: 'Display disponibile (posteriore)',
				display_hinten_typ_const_id: 'Tipo di display (posteriore)',
				display_hinten_aufloesung: 'Risoluzione display (posteriore) [pixel]',
				display_seitlich_vorhanden: 'Display disponibile (laterale)',
				display_seitlich_typ_const_id: 'Tipo di display (laterale)',
				display_seitlich_aufloesung: 'Risoluzione display (laterale) [pixel]',
				display_infotainment_vorhanden: 'Display infotainment disponibile',
				display_infotainment_groesse: 'Grandezza display infotainment [pollici]',
				display_fahrgast_vorhanden: 'Display informazioni passeggeri disponibile',
				display_fahrgast_groesse: 'Grandezza display informazioni passeggeri [pollici]',
				videoueberwachung_vorhanden: 'Videosorveglianza disponibile',
				akustisches_ansagesystem_vorhanden: 'Sistema di annunci acustici disponibile',
				fgzs_vorhanden: 'Sistema di contapasseggeri disponibile',
				fgzs_anzahl_tueren: 'Porte con sistema di contapasseggeri',
				has_ticketing_old: 'Vecchio sistema di ticketing disponibile',
				has_ticketing_new: 'Nuovo sistema di ticketing disponibile',
				last_update_timestamp: 'ultimo aggiornamento',
				sta_matrikelnummer: 'Matricola ITCS'
			}
		},
		'vehicles.subconcessionaire': {
			name: 'Subconcessionario |||| Subconcessionari',
			fields: {
				name_de: 'Nome (de)',
				name_it: 'Nome (it)'
			}
		},
		'vehicles.constants': {
			name: 'Costante |||| Costanti'
		},
		'vehicles.operators_vw':{
			name: 'Operatore |||| Operatori',
			fields: {
				identifier: 'Id',
				name: 'Nome',
				short_name: 'Abbreviazione'
			}
		},
		'administration.users': {
			name: 'Utente |||| Utenti',
			fields: {
				username: 'Nome utente',
				email: 'Email',
				role: 'Ruolo',
			}
		},
		'administration.user_contexts': {
			name: 'User-Context |||| User-Contexts',
			fields: {
				context_id: 'Id'
			}
		},
		'administration.user_delegations': {
			name: 'Delega |||| Deleghe',
			fields: {
				delegation_type: 'Tipo',
				delegation_identifier: 'Id'
			}
		},
		'operators_portal.line_events_vw': {
			name: 'Giustificazione |||| Giustificazioni',
			updated: "Giustificazione aggiornata |||| %{smart_count} Giustificazioni aggiornati",
			fields: {
				type_const_id: 'Tipo',
				reason_approved: 'confermato',
				reason_const_id: 'Giustificazione',
				reason_description: 'Descrizione aggiuntiva',
				operator_id: 'Operatore',
				operator_id_displayed: 'Operatore',
				line_public_code: 'Linea',
				start_stop_place: 'Partenza',
				start_stop_place_de: 'Partenza',
				start_stop_place_it: 'Partenza',
				end_stop_place: 'Arrivo',
				end_stop_place_de: 'Arrivo',
				end_stop_place_it: 'Arrivo',
				start_time: 'Ora di partenza',
				date: 'Data',
				details: 'Detagli',
				reason_approved_description: 'Motivazione',
				is_locked: 'bloccato',
				vehicle_id: 'Veicolo',
				line_suffix: 'Zona',
				rideid: 'n. corsa',
				distance_planned: 'Lunghezza [m]',
				distance_done: 'Lunghezza effettuata [m]',
				distance_reinforced: 'Distanza potenziata [m]',
				time_reinforced: 'Tempo di viaggio potenziato [min]',
				substitute_journey: 'corsa sostitutiva effettuata',
				lot_identifier: 'Lotto',
				start_time_without_date: 'Ora di partenza',
				end_time_without_date: 'Ora di arrivo'
			},
			msg: {
				rt_no_realtime: 'solo una stima plausibile, nessun dato in tempo reale verificato'
			}
		},
		'operators_portal.rt_events_vw': {
			name: 'Corsa |||| Corse',
			fields: {
				operator_id: 'Operatore',
				line_public_code: 'Linea',
				start_stop_place: 'Partenza',
				start_stop_place_de: 'Partenza',
				start_stop_place_it: 'Partenza',
				end_stop_place: 'Arrivo',
				end_stop_place_de: 'Arrivo',
				end_stop_place_it: 'Arrivo',
				date: 'Data',
				details: 'Detagli',
				vehicle_id: 'Veicolo',
				line_suffix: 'Zona',
				rideid: 'n. corsa'
			}
		},
		'operators_portal.manual_data_uploads': {
			name: 'VDV454 Upload |||| VDV454 Uploads',
			fields: {
				operator_id: 'Operatore',
				file_name: 'Filename',
				is_valid: 'ok'
			}
		},
		'operators_portal.strike': {
			name: 'Sciopero |||| Scioperi',
			fields: {
				operator_id: 'Operatore',
				begin_ts: 'Inizio',
				end_ts: 'Fine'
			}
		},
		'operators_portal.unplanned_changes': {
			name: 'Mod. orario non pianificata |||| Mod. orario non pianificate',
			fields: {
				operator_id: 'Operatore',
				line_public_code: 'Linea',
				begin_ts: 'Inizio',
				end_ts: 'Fine',
				description: 'Nota',
				plus_km_l72h: 'Aumento km nelle prime 72 ore',
				plus_km_g72h: 'Aumento km dopo le prime 72 ore',
				minus_km_g72h: 'Riduzione km dopo le prime 72 ore'
			}
		},
		'operators_portal.outage_intervals': {
			name: 'Interruzione funicolare Renon |||| Interruzioni fun. Renon',
			fields: {
				operator_id: 'Operatore',
				line_public_code: 'Linea',
				begin_ts: 'Inizio',
				end_ts: 'Fine',
				reason_const_id: 'Giustificazione',
				reason_description: 'Nota'
			}
		},
		'operators_portal.manual_passenger_counting': {
			name: 'Contapasseggeri |||| Contapasseggeri',
			fields: {
				operator_id: 'Operatore',
				line_public_code: 'Linea',
				date: 'Data',
				counting_inbound: 'Rilevamento (Stazione a valle/Andata)',
				counting_outbound: 'Rilevamento (Stazione a monte/Ritorno)',
				description: 'Nota'
			}
		},
		'operators_portal.reports_vw': {
			name: 'Report |||| Report',
			updated: "Report aggiornato |||| %{smart_count} Report aggiornati",
			fields: {
				operator_ids: 'Operatore',
				type_id: 'Tipo',
				date_from: 'valido da',
				date_to: 'valido a',
				timestamp: 'ultimo aggiornamento',
				is_official: 'definitivo',
				lock_attachments: 'Lock A10'
			}
		},
		'operators_portal.extra_bus_information_vw': {
			name: 'Modifica orario |||| Modifiche orario',
			updated: "Modifica orario aggiornato |||| %{smart_count} modifiche orario aggiornati",
			fields: {
				schedule_year: 'Orario',
				operator_id: 'Operatore',
				operator_id_displayed: 'Operatore',
				line_public_code: 'Linea',
				start_time: 'Ora di partenza',
				start_stop_place: 'Partenza',
				first_date: 'valido da',
				last_date: 'valido a',
				verkehrstage: 'Numero giornate',
				distance_m: 'Distanza',
				journey_time_min: 'Tempo di percorrenza',
				requires_extra_bus: 'Extra bus / risparmio bus'
			}
		},
		'operators_portal.report_attachments': {
			name: 'Allegato A10 |||| Allegati A10'
		},
		'operators_portal.segment_changes_log': {
			name: 'Correzione di percorso parziale |||| Correzioni di percorsi parziali',
			fields: {
				service_link_ref: 'Percorso parziale',
				description: 'Descrizione',
				last_update_timestamp: 'ultimo aggiornamento'
			}
		},
		'operators_portal.service_link_mvw': {
			name: 'Percorso parziale |||| Percorsi parziali',
			fields: {
				q: 'Percorso parziale'
			}
		},
		'operators_portal.sasa_journey_codes_vw': {
			name: 'ITCS code |||| ITCS codes',
			fields: {
				line_public_code: 'Linea',
				date: 'Data',
				lot_identifier: 'Lotto',
				departure_time: 'Ora di partenza',
				arrival_time: 'Ora di arrivo',
				start_global_id: 'globalID (partenza)',
				start_stop_place_de: 'Partenza',
				start_stop_place_it: 'Partenza',
				end_global_id: 'globalID (arrivo)',
				line_private_code: 'Private code (linea)',
				journey_private_code: 'Private code (corsa)',
				itcs_code: 'ITCS code',
				linien_kursnummer: 'Codice linea',
				kursnummer: 'Codice corsa'
			}
		},
		'operators_portal.segment_changes': {
			name: 'Mod. di percorso parziale |||| Mod. di percorsi parziali',
			updated: "Mod. di percorso parziale aggiornata |||| %{smart_count} Mod. di percorsi parziali aggiornati",
			fields: {
				schedule_year: 'Orario',
				schedule_year_it: 'Orario',
				from_place_name_it: 'Partenza',
				to_place_name_it: 'Arrivo',
				operational_context_ref: 'Betriebszweig',
				operational_context_name_it: 'Betriebszweig',
				distance_jd: 'Orario annuale [m]',
				distance_cns: 'Consuntivo [m]',
				last_data_update: 'Ultimo aggiornamento della lunghezza',
				check_operator: 'Check operatore',
				check_sta: 'Check STA'
			}
		},
		'operators_portal.itcs_efa': {
			name: 'ITCS Analisi delle corse |||| ITCS Analisi delle corse',
			fields: {
				lot: 'Lotto',
				datum: 'Data',
				datum_von: 'Data da',
				datum_bis: 'Data a',
				source: 'Fonte',
				operator_id: 'Operatore',
				operator_id_displayed: 'Operatore',
				line: 'Linea',
				fahrt: 'Corsa',
				line_public_code: 'Linea',
				line_suffix: 'Zona',
				abfahrtszeit_von: 'da',
				abfahrtszeit_bis: 'a',
				abfahrtszeit_soll: 'Orario di partenza pian',
				abfahrtszeit_ist: 'Orario di partenza cns',
				fahrzeugnummer: 'Veicolo',
				start: 'Partenza GlobalID',
				start_stop_place_name: 'Partenza',
				start_stop_place_name_de: 'Partenza',
				start_stop_place_name_it: 'Partenza',
				ziel: 'Arrivo GlobalID',
				ziel_stop_place_name: 'Arrivo',
				ziel_stop_place_name_de: 'Arrivo',
				ziel_stop_place_name_it: 'Arrivo',
				basisversion: 'Basisversion',
				richtung: 'Direzione',
				verlaufsnummer: 'Sequenza',
				haltepunkt: 'Fermata GlobalID',
				haltepunkt_stop_place_name: 'Fermata',
				haltepunkt_stop_place_name_de: 'Fermata',
				haltepunkt_stop_place_name_it: 'Fermata',
				soll_an: 'Arrivo pianificato',
				soll_ab: 'Partenza pianificata',
				ist_an: 'Arrivo consuntivo',
				ist_ab: 'Partenza consuntivo',
				delta_ab: 'Var. Partenza',
				delta_an: 'Var. Arrivo',
				st_aend_urs: 'Causa del cambiamento',
				fahrgastwechselzeit: 'Tempo di cambio passeggeri',
				error_class: 'Classe di errore'
			},
			errorClasses: {
				ERR_1: 'Partenza anticipata',
				ERR_2: 'Parzialmente saltata',
				ERR_3: 'Corsa anticipata/ritardata',
				ERR_4: 'Orario non rispettato',
				ERR_5: 'Ist-Zeit sempre uguale'
			}
		},
	},
	localizedName: 'name_it',
	localizedNameDefaultDe: 'name_it',
	dropzoneText: 'Drag&drop file o cliccare per selezionare i file da caricare.',
	booleanAndUndefined: {
		yes: 'si',
		no: 'no',
		undefined: 'non definito'
	},
	filter_from: 'da',
	filter_to: 'a',
	lineSuffix: {
		bz: 'Bolzano',
		me: 'Merano',
		empty: 'suburbano'
	},
	language: {
		language: 'Lingua',
		setLanguage: 'Imposta lingua'
	},
	auth:{
		forgotPassword: 'Password dimenticata?',
		password_expired: 'La password è scaduta. È necessario fare clic sul pulsante "Password dimenticata?" e impostare una nuova password.'
	},
	administration: {
		addContext: 'Aggiungi contesto',
		addDelegation: 'Aggiungi delega',
		visibleContexts: 'Contesti visibili',
		activeDelegations: 'Deleghe attive'
	},
	operators_portal: {
		attachments: 'Allegati',
		report_attachments: 'Allegato A10',
		bulkAddReasonAction: 'Giustifica'
	},
	localizedFields: {
		start_stop_place: 'start_stop_place_it',
		end_stop_place: 'end_stop_place_it',
		stop_place_name: 'stop_place_name_it',
		schedule_year: 'schedule_year_it',
		from_place_name: 'from_place_name_it',
		to_place_name: 'to_place_name_it',
		operational_context_name: 'operational_context_name_it',
		name: 'name_it',
		start_stop_place_name: 'start_stop_place_name_it',
		ziel_stop_place_name: 'ziel_stop_place_name_it',
		haltepunkt_stop_place_name: 'haltepunkt_stop_place_name_it',
	}
}
