import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';
import germanMessages from 'ra-language-german';
import italianMessages from 'ra-language-italian';
import germanTranslations from './translations/de';
import italianTranslations from './translations/it';

let messages = {
    'de': {...germanMessages, ...germanTranslations},
    'it': {...italianMessages, ...italianTranslations}
};

export const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.de,
    messages[resolveBrowserLocale('de', { fullLocale: true })]? resolveBrowserLocale('de', { fullLocale: true }): 'de',
    [
        { locale: 'de', name: 'DE' },
        { locale: 'it', name: 'IT' }
    ],
);