import React, { Fragment, useState } from 'react';
import {
    List,
    Datagrid,
    ReferenceField,
    DateField,
    TextField,
    Labeled,
    EditButton,
    Filter,
    Toolbar,
    ReferenceInput,
    SelectInput,
    SelectArrayInput,
    Edit,
    SimpleForm,
    ReferenceManyField,
    ReferenceArrayField,
    DateInput,
    TopToolbar,
    DeleteButton,
    Button,
    LongTextInput,
    TextInput,
    NullableBooleanInput,
    BooleanField,
    BooleanInput,
    SaveButton,
    AutocompleteInput,
    NumberField,
    NumberInput,
    Create,
    DateTimeInput,
    SingleFieldList,
    ChipField,
    FunctionField,
    DeleteWithConfirmButton,
    ArrayField, TimeInput
} from 'react-admin';
import { Checkbox } from '@mui/material';
import { Pagination, ShowButton, Show, SimpleShowLayout } from 'react-admin';
import { useTranslate, usePermissions, useRecordContext, useRefresh, useResourceContext, useDataProvider } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { FormDataConsumer } from 'react-admin';
import BulkAddReasonAction from './line_events/BulkAddReasonAction';
import ListCheckboxInput from './ListCheckboxInput';
import { FormControl, Typography, Tooltip, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import SaveIcon from '@mui/icons-material/Save';
import FileUpload from '@mui/icons-material/FileUpload';
import Dropzone from 'react-dropzone';
import * as xlsx from 'xlsx';
import constants from '../constants';

var moment = require('moment');
var fileDownload = require('js-file-download');
var base64js = require('base64-js');
var Diff = require('diff');

const toXlsx = (records) => {
    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(records);
    xlsx.utils.book_append_sheet(wb, ws);

    xlsx.writeFile(wb, 'data_export.xlsx');
}

const xlsxExporter = (records, fetchRelatedRecords) => {
    if(records && records[0] && records[0].type_const_id){
        fetchRelatedRecords(records, 'type_const_id', 'operators_portal.constants').then(type_constants => {
            fetchRelatedRecords(records, 'reason_const_id', 'operators_portal.constants').then(reason_constants => {
                const new_records = records.map(record => ({
                    ...record,
                    type_const_de: type_constants[record.type_const_id].name_de,
                    type_const_it: type_constants[record.type_const_id].name_it,
                    reason_const_de: reason_constants[record.reason_const_id].name_de,
                    reason_const_it: reason_constants[record.reason_const_id].name_it
                }));
                toXlsx(new_records);
            });
        });
    } else {
        toXlsx(records);
    }
};

const EventsBulkActionButtons = () => {
    const { permissions } = usePermissions();
    return (
    <>
        {[
            (permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'sta_operator') ? <BulkAddReasonAction key="addReasonAction" /> : null
        ]}
    </>);
}

const EventsFilter = () => {
    const translate = useTranslate();

    const choicesBooleanAndUndefined = [
        { value: 'undefined', name: translate('booleanAndUndefined.undefined') },
        { value: true, name: translate('booleanAndUndefined.yes') },
        { value: false, name: translate('booleanAndUndefined.no') }
    ];

    const choicesLineSuffix = [
        { value: '', name: translate('lineSuffix.empty') },
        { value: 'BZ', name: translate('lineSuffix.bz') },
        { value: 'ME', name: translate('lineSuffix.me') }
    ];

    return [
		<ReferenceInput source="lot_identifier" reference="operators_portal.lots_vw" sort={{ field: 'ordering', order: 'ASC' }} perPage={12} alwaysOn>
            <SelectInput optionText={translate('localizedNameDefaultDe')} />
        </ReferenceInput>,
        <ReferenceInput source="type_const_id@in" reference="operators_portal.constants" filter={{category: "event_type"}} sort={{ field: translate('localizedName'), order: 'ASC' }} alwaysOn>
            <SelectArrayInput optionText={translate('localizedName')} label="resources.operators_portal.line_events_vw.fields.type_const_id" />
        </ReferenceInput>,
//        {/*<BooleanInput source="is_locked" alwaysOn>*/}
//		{/*<SelectInput source="reason_approved" choices={choicesBooleanAndUndefined} optionText="name" optionValue="value" />*/}
        <ReferenceInput source="reason_const_id" reference="operators_portal.constants" filter={{category: "reason_type"}} sort={{ field: translate('localizedName'), order: 'ASC' }} alwaysOn>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>,
        <TextInput source="line_public_code" alwaysOn/>,
        <DateInput source="date" alwaysOn/>,
        <DateInput source="date@gte" label={translate('filter_from')} />,
        <DateInput source="date@lte" label={translate('filter_to')} />,
		<TimeInput source="start_time_without_date" type="time" />,
		<TimeInput source="end_time_without_date" type="time" />,
		<TextInput source={translate('localizedFields.start_stop_place')} />,
		<TextInput source={translate('localizedFields.end_stop_place')} />,
        <TextInput source="vehicle_id" alwaysOn/>,
        <TextInput source="rideid" alwaysOn/>,
        <SelectInput source="line_suffix" choices={choicesLineSuffix} optionText="name" optionValue="value" alwaysOn />,
        <ReferenceInput source="operator_id_displayed" reference="operators_portal.operators_vw" sort={{ field: 'name', order: 'ASC' }} perPage={100} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>,
    ];
}

const EventsListEditButton = () => {
    const record = useRecordContext();
    return (
        <>
            {
                (record && record.has_edit_permission)
                    ? (<EditButton/>)
                    : (<ShowButton/>)}
        </>);
}

export const EventsList = () => {
    const translate = useTranslate();

    return (
    <List exporter={xlsxExporter} perPage={50} sort={{ field: 'start_time', order: 'ASC' }} filterDefaultValues={{ date: moment().subtract(1, 'days').format('YYYY-MM-DD') }} filters={EventsFilter()} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} >
        <Datagrid bulkActionButtons={<EventsBulkActionButtons />} isRowSelectable={ record => record.has_edit_permission }>
            <TextField source="line_public_code"/>
            <TextField source="line_suffix"/>
            <DateField source="start_time" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric' }} />
            <TextField source={translate('localizedFields.start_stop_place')} />
			<TextField source={translate('localizedFields.end_stop_place')} />
            {/*<TextField source={translate('localizedFields.end_stop_place')} />*/}
            <ReferenceField source="type_const_id" reference="operators_portal.constants">
                <TextField source={translate('localizedName')} />
            </ReferenceField>
            <ReferenceField source="operator_id_displayed" reference="operators_portal.operators_vw">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="reason_const_id" reference="operators_portal.constants">
                <TextField source={translate('localizedName')} />
            </ReferenceField>
            <TextField source="vehicle_id"/>
            <TextField source="rideid"/>
			<EventsListEditButton />
        </Datagrid>
    </List>)
}

export const EventsList2 = () => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    return (
    <List exporter={xlsxExporter} perPage={50} sort={{ field: 'start_time', order: 'ASC' }} filterDefaultValues={{ date: moment().subtract(1, 'days').format('YYYY-MM-DD') }} bulkActionButtons={<EventsBulkActionButtons />} filters={EventsFilter()} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} >
        <Datagrid>
            <TextField source="line_public_code"/>
            <TextField source="line_suffix"/>
            <DateField source="start_time" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric' }} />
            <TextField source={translate('localizedFields.start_stop_place')} />
            {/*<TextField source={translate('localizedFields.end_stop_place')} />*/}
            <ReferenceField source="type_const_id" reference="operators_portal.constants">
                <TextField source={translate('localizedName')} />
            </ReferenceField>
            <ReferenceField source="operator_id" reference="operators_portal.operators_vw">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="reason_const_id" reference="operators_portal.constants">
                <TextField source={translate('localizedName')} />
            </ReferenceField>
            <TextField source="vehicle_id"/>
            <TextField source="rideid"/>
			{
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'monitoring' || permissions.role === 'sta_operator'))
                    ? (<EditButton/>)
                    : (<ShowButton/>)
            }
        </Datagrid>
    </List>)
}

const formatDetails = (details, type_const_id, translate) => {
	const styleDefault = { color: 'grey' };
	const styleRemoved = { color: 'grey', textDecoration: 'line-through' };
	const styleAdded = { color: 'black' };
	const styleDelay = { color: 'red' };

	var planned = [];
	if(details.nstops) planned = details.nstops;
	if(details.planned && details.planned.nstops) planned = details.planned.nstops;
	var done = [];
	if(details.nstops) done = details.nstops;
	if(details.done && details.done.nstops) done = details.done.nstops;

	//replace ':' with '_' to avoid splitting within a globalid
	var plannedStr = planned.map(function(stop, index){ if (stop.stop_place_globalid) return stop.stop_place_globalid.split(':').join('_'); else return stop.globalid.split(':').join('_');}).join();
	var doneStr = done.map(function(stop, index){ if (stop.stop_place_globalid) return stop.stop_place_globalid.split(':').join('_'); else return stop.globalid.split(':').join('_');}).join();

	var diff = Diff.diffWords(plannedStr, doneStr);

	var plannedIdx = 0;
	var doneIdx = 0;
	var result = [];
	for (var diffPart of diff) {

		if (diffPart.value === ',') continue; //diff parts consisting of only a single comma have to be skipped

		for (var item of diffPart.value.replace(/(^,)|(,$)/g, '').split(',')) { //remove leading and trailing commas before split
			var isFirstStop = false;
			var currentStop;
			var spanStyle;

			if (diffPart.added === true) {
				if (doneIdx === 0) isFirstStop = true;
				currentStop = done[doneIdx++];
				spanStyle = styleAdded;
			} else if (diffPart.removed === true) {
				if (plannedIdx === 0) isFirstStop = true;
				currentStop = planned[plannedIdx++];
				spanStyle = styleRemoved;
			} else {
				if (doneIdx === 0) isFirstStop = true;
				currentStop = done[doneIdx];
				currentStop.distance_from_start = planned[plannedIdx].distance_from_start;
				spanStyle = styleDefault;
				doneIdx++;
				plannedIdx++;
			}
			var hasRealtime = (isFirstStop && currentStop.departure_timestamp_rt && currentStop.departure_timestamp_pl) || (currentStop.arrival_timestamp_rt && currentStop.arrival_timestamp_pl);
			var delay = (hasRealtime)
                ? (isFirstStop)
					? moment.duration(moment.parseZone(currentStop.departure_timestamp_rt).diff(moment.parseZone(currentStop.departure_timestamp_pl)))
                    : moment.duration(moment.parseZone(currentStop.arrival_timestamp_rt).diff(moment.parseZone(currentStop.arrival_timestamp_pl)))
                : null;
			var ahead = (hasRealtime && currentStop.departure_timestamp_pl)
				? moment.duration(moment.parseZone(currentStop.departure_timestamp_rt).diff(moment.parseZone(currentStop.departure_timestamp_pl)))
                : null;
			var hasDelay = hasRealtime && delay && (delay >= moment.duration(5, 'minutes') || ahead <= moment.duration(-1, 'minutes'));
			if (hasDelay) { spanStyle = styleDelay; }
			var text = ((isFirstStop) ? '' : ' - ')
				+ currentStop[translate('localizedFields.stop_place_name')]
				+ ' (' + ((isFirstStop) ? moment.parseZone(currentStop.departure_timestamp_pl).format('HH:mm') : moment.parseZone(currentStop.arrival_timestamp_pl).format('HH:mm'))
				+ (hasRealtime ? ((delay >= moment.duration(0, 's')) ? ' +' : ' -') + moment.utc(Math.abs(delay.asMilliseconds())).format("m:ss") : '')
				+ ((!isFirstStop && currentStop.distance_from_start) ? ', ' + currentStop.distance_from_start + 'm' : '')
				+ ')';
			result.push(<span style={ spanStyle }>{text}</span>);
		}
	}

	return result;
}

const Details = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    var result = formatDetails(record.details, record.type_const_id, translate);
    return (
        <div>
            {result}
        </div>
    );
};

const DetailsField = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    return (
    <FormControl>
        <Typography variant="caption">{translate('resources.operators_portal.line_events_vw.fields.details')}</Typography>
        <Details record={record} />
    </FormControl>)
};

const createAttachmentData = (file, filename, id) => {
    return {
        line_event_id: id,
        file_base64: file,
        file_name: filename
    };
};

const createReportAttachmentData = (file, filename, id) => {
    return {
        report_id: id,
        file_base64: file,
        file_name: filename
    };
}

const createManualUploadData = (file, filename) => {
    return {
        uploaded_xml: file,
        file_name: filename
    };
}

const SitDropzone = ({ refreshView, ...props} ) => {

    const translate = useTranslate();
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const sequentialFileUpload = (files) => {
        if(files.length > 0) {
            const file = files.splice(0, 1)[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                dataProvider.create(props.targetResource, { data: props.getDataFnc(event.target.result, file.name, record ? record.id : null) })
                .then(() => {
                    sequentialFileUpload(files);
                });
            };
            if(props.base64){
                reader.readAsDataURL(file);
            } else {
                reader.readAsText(file);
            }

        } else {
            refresh();
        }
    }

    return (
          <Dropzone onDrop={sequentialFileUpload} {...props} maxFiles={props.maxFiles} accept={props.accept}>
            {({getRootProps, getInputProps, isDragActive}) => {
              return (
                <div {...getRootProps()} style={{
                    background: '#efefef',
                    cursor: 'pointer',
                    padding: '0.2rem',
                    marginTop: '0.2em',
                    textAlign: 'center',
                    color: '#999'}}>
                  <input {...getInputProps()} />
				  <Tooltip title={translate('dropzoneText')}>
						<FileUpload />
				  </Tooltip>
                </div>
              )
            }}
          </Dropzone>
    );
}

const DownloadButton = props => {
    const { source } = props;
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const resource = useResourceContext();
    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = (e) => {
        setIsLoading(true);
        dataProvider.getOne(resource, { id: record.id })
            .then(result => {
                if(props.base64){
                    var base64 = result.data[props.fileCol];
                    var blob = base64.substring(base64.indexOf(',') + 1);
                    fileDownload(base64js.toByteArray(blob), result.data[props.filenameCol]);
                } else {
                    fileDownload(result.data[props.fileCol], result.data[props.filenameCol]);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    };

		if(props.small)
			return (
				<Tooltip title={record[props.filenameCol]}>
				  <IconButton disabled={isLoading} color="primary"  onClick={ () => handleOnClick() }>
					<SaveIcon />
				  </IconButton>
				</Tooltip>
			);
		else
			return (
				<Button disabled={isLoading} label="Download" color="primary" onClick={handleOnClick} >
					<SaveIcon />
				</Button>
			);
}

const NoDeleteToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>)
};

export const EventEdit = () => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
    <Edit actions={false}>
        <SimpleForm toolbar={<NoDeleteToolbar/>}>
			<ReferenceInput source="type_const_id" reference="operators_portal.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: 'event_type'}} >
                <SelectInput optionText={translate('localizedName')} />
            </ReferenceInput>
            <ReferenceField source="operator_id_displayed" reference="operators_portal.operators_vw">
                <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                    <TextField source="name" />
                </Labeled>
            </ReferenceField>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <DateField source="date" />
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="line_public_code"/>
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="line_suffix"/>
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="vehicle_id"/>
            </Labeled>
            <DetailsField />
            <FormDataConsumer>
                {({ formData, ...rest }) => [constants.event_type.NO_JOURNEY,constants.event_type.INCOMPLETE_JOURNEY].includes(formData.type_const_id) &&
                    <TextInput disabled source="distance_planned" {...rest} />
                }
            </FormDataConsumer>
            {
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'sta_operator'))
                    ?
                    ([
						<NumberInput source="distance_done"/>,
                        <ReferenceInput key="reasonInput" source="reason_const_id" reference="operators_portal.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: 'reason_type'}} >
                            <SelectInput optionText={translate('localizedName')} />
                        </ReferenceInput>,
                        <FormDataConsumer>
                            {({ formData, ...rest }) => [constants.event_type.NO_JOURNEY,constants.event_type.INCOMPLETE_JOURNEY].includes(formData.type_const_id) &&
                                <BooleanInput source="substitute_journey" {...rest} />
                            }
                        </FormDataConsumer>,
                        <TextInput multiline key="reasonDescriptionInput" source="reason_description" />,
						<NumberInput source="distance_reinforced"/>,
						<NumberInput source="time_reinforced"/>
                    ])
                    :
                    ([
						<TextInput disabled source="distance_done"/>,
                        <ReferenceField key="reasonField" source="reason_const_id" reference="operators_portal.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: 'reason_type'}} >
                            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                                <TextField source={translate('localizedName')} />
                            </Labeled>
                        </ReferenceField>,
                        <FormDataConsumer>
                            {({ formData, ...rest }) => [constants.event_type.NO_JOURNEY,constants.event_type.INCOMPLETE_JOURNEY].includes(formData.type_const_id) &&
                                <TextInput disabled source="substitute_journey" {...rest} />
                            }
                        </FormDataConsumer>,
                        <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                            <TextField key="reasonDescriptionField" source="reason_description" />
                        </Labeled>,
                        <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
    						<TextInput disabled source="distance_reinforced"/>
    					</Labeled>,
    					<Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
    						<TextInput disabled source="time_reinforced"/>
    					</Labeled>
                    ])
            }
			{/*
            <ReferenceManyField label={translate('operators_portal.attachments')} reference="operators_portal.attachments" target="line_event_id">
                <Datagrid>
                    <TextField source="file_name" />
                    <DownloadButton resource="attachments" base64 fileCol="file_base64" filenameCol="file_name" />
                    {
                        (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'sta_operator'))
                        ? (<DeleteButton redirect={ location.pathname } />) : null
                    }
                </Datagrid>
            </ReferenceManyField>
            {
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'sta_operator'))
                ? (<SitDropzone targetResource="operators_portal.attachments" getDataFnc={createAttachmentData} base64/>) : null
            }
			*/}
            {/*
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'monitoring'))
                    ?
                    ([
                        <NullableBooleanInput key="approvedInput" source="reason_approved"/>,
                        <TextInput multiline key="approvedDescriptionInput" source="reason_approved_description" />
                    ])
                    :
                    []
            */}
        </SimpleForm>
    </Edit>);
}

export const EventEdit2 = () => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const location = useLocation();
    return (
    <Edit>
        <SimpleForm toolbar={<NoDeleteToolbar/>}>
            <ReferenceField source="type_const_id" reference="operators_portal.constants">
                <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                    <TextField source={translate('localizedName')} />
                </Labeled>
            </ReferenceField>
            <ReferenceField source="operator_id" reference="operators_portal.operators_vw">
                <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                    <TextField source="name" />
                </Labeled>
            </ReferenceField>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <DateField source="date" />
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="line_public_code"/>
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="line_suffix"/>
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="vehicle_id"/>
            </Labeled>
            <DetailsField />
			<TextInput disabled source="distance_planned" />
            {
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'sta_operator'))
                    ?
                    ([
						<NumberInput source="distance_done" />,
                        <ReferenceInput key="reasonInput" source="reason_const_id" reference="operators_portal.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: 'reason_type'}} >
                            <SelectInput optionText={translate('localizedName')} />
                        </ReferenceInput>,
                        <TextInput multiline key="reasonDescriptionInput" source="reason_description" />,
						<NumberInput source="distance_reinforced" />,
                    ])
                    :
                    ([
						<TextInput disabled source="distance_done" />,
                        <ReferenceField key="reasonField" source="reason_const_id" reference="operators_portal.constants" sort={{ field: translate('localizedName'), order: 'ASC' }} filter={{category: 'reason_type'}} >
                            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                                <TextField source={translate('localizedName')} />
                            </Labeled>
                        </ReferenceField>,
                        <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                            <TextField key="reasonDescriptionField" source="reason_description" />
                        </Labeled>,
						<TextInput disabled source="distance_reinforced" />,
                    ])
            }
            <ReferenceManyField label={translate('operators_portal.attachments')} reference="operators_portal.attachments" target="line_event_id">
                <Datagrid>
                    <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                        <TextField source="file_name" />
                    </Labeled>
                    <DownloadButton resource="attachments" base64 fileCol="file_base64" filenameCol="file_name" />
                    {
                        (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'sta_operator'))
                        ? (<DeleteButton redirect={ location.pathname } />) : null
                    }
                </Datagrid>
            </ReferenceManyField>
            {
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator' || permissions.role === 'sta_operator'))
                ? (<SitDropzone targetResource="operators_portal.attachments" getDataFnc={createAttachmentData} base64/>) : null
            }
            {/*
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'monitoring'))
                    ?
                    ([
                        <NullableBooleanInput key="approvedInput" source="reason_approved"/>,
                        <TextInput multiline key="approvedDescriptionInput" source="reason_approved_description" />
                    ])
                    :
                    []
            */}
        </SimpleForm>
    </Edit>);
}

export const EventShow = () => {
    const translate = useTranslate();
    return (
    <Show actions={false}>
        <SimpleShowLayout>
            <ReferenceField source="type_const_id" reference="operators_portal.constants">
                <TextField source={translate('localizedName')} />
            </ReferenceField>
            <ReferenceField source="operator_id_displayed" reference="operators_portal.operators_vw">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="date" />
            <TextField source="line_public_code"/>
            <TextField source="line_suffix"/>
            <TextField source="vehicle_id"/>
            <DetailsField />
			<NumberField source="distance_planned"/>
			<NumberField source="distance_done"/>
			<ReferenceField key="reasonInput" source="reason_const_id" reference="operators_portal.constants" allowEmpty >
                <TextField source={translate('localizedName')} />
            </ReferenceField>
			<BooleanField source="substitute_journey"/>
    		<TextField source="reason_description" />
			<NumberField source="distance_reinforced" />
			<NumberField source="time_reinforced"/>
            <ReferenceManyField label={translate('operators_portal.attachments')} reference="operators_portal.attachments" target="line_event_id">
                <Datagrid>
                    <TextField source="file_name" />
                    <DownloadButton resource="attachments" base64 fileCol="file_base64" filenameCol="file_name" />
                </Datagrid>
            </ReferenceManyField>
			{/*<BooleanField key="approvedInput" source="reason_approved"/>
			<TextField source="reason_approved_description" />*/}
        </SimpleShowLayout>
    </Show>);
}

const ManualUploadsFilter = () => {
    return [
        <TextInput source="operator_id" alwaysOn/>,
        <TextInput source="file_name" alwaysOn/>
    ]
}

const ManualUploadsActions = () => {
    return (
        <TopToolbar>
            <SitDropzone targetResource="operators_portal.manual_data_uploads" getDataFnc={createManualUploadData} accept="text/xml"/>
        </TopToolbar>
    );
}

export const ManualUploadsList = () => {
    return (
    <List filters={ManualUploadsFilter()} actions={<ManualUploadsActions/>} >
        <Datagrid bulkActionButtons={false}>
            <TextField source="operator_id"/>
            <TextField source="file_name" />
            <NumberField source="journeys_found" />
            <NumberField source="journeys_imported" />
            <DownloadButton resource="manual_data_uploads" fileCol="uploaded_xml" filenameCol="file_name" />
        </Datagrid>
    </List>)
}

const StrikeFilter = () => {
    return [
        <ReferenceInput source="operator_id" reference="operators_portal.operators_vw" sort={{ field: 'name', order: 'ASC'}} perPage={100} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    ]
}

export const StrikeList = () => {
    const { permissions } = usePermissions();
    return (
    <List filters={StrikeFilter()}>
        <Datagrid>
            <ReferenceField source="operator_id" reference="operators_portal.operators_vw">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="begin_ts" showTime/>
            <DateField source="end_ts" showTime/>
            {
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator'))
                    ? (<EditButton/>)
					: null
            }
        </Datagrid>
    </List>)
}

const StrikeDetail = () => {
    return (
    <SimpleForm>
        <ReferenceInput source="operator_id" reference="operators_portal.operators_vw" sort={{ field: 'name', order: 'ASC'}} perPage={100}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <DateTimeInput source="begin_ts" type="datetime-local"/>
        <DateTimeInput source="end_ts" type="datetime-local"/>
    </SimpleForm>)
}

export const StrikeEdit = () => {
    return (
    <Edit>
        <StrikeDetail/>
    </Edit>)
};

export const StrikeCreate = () => {
    return (
    <Create>
        <StrikeDetail/>
    </Create>)
};

const ReportsFilter = () => {
    const translate = useTranslate();
    return [
        <ReferenceInput source="type_id" reference="operators_portal.report_types" sort={{ field: translate('localizedName'), order: 'ASC' }} perPage={100} alwaysOn>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>,
        <DateInput source="date_from" alwaysOn/>,
        <ReferenceInput source="operator_ids@cs" reference="operators_portal.operators_vw" sort={{ field: 'name', order: 'ASC' }} perPage={100} alwaysOn>
            <SelectInput optionText="name" label="resources.operators_portal.reports_vw.fields.operator_ids" />
        </ReferenceInput>
    ];
};

const UploadA10Button = () => {
    const record = useRecordContext();
    const { permissions } = usePermissions();

    return (
    permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator') && record && !record.lock_attachments
            ? (<SitDropzone targetResource="operators_portal.report_attachments" getDataFnc={createReportAttachmentData} base64 />)
    		: null
    )
}

const ReportAttachmentList  = props => {
    const recordReport = useRecordContext();
    const { permissions } = usePermissions();
    const translate = useTranslate();

	return(
		<ReferenceManyField label={translate('operators_portal.report_attachments')} reference="operators_portal.report_attachments" target="report_id">
			<SingleFieldList linkType={false}>
				<FunctionField render={record => (
					<div>
						{(record.file_name.length < 20) ? record.file_name : record.file_name.substring(0,10) + '...' + record.file_name.substring(record.file_name.length - 7)}
						<DownloadButton small resource="operators_portal.report_attachments" base64 fileCol="file_base64" filenameCol="file_name" />
						    {(permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator') && recordReport && !recordReport.lock_attachments
						            ? (<DeleteButton redirect="false" sx={{ ' .MuiButton-startIcon': { marginRight: '0px' }, }} label="" resource="operators_portal.report_attachments" />)
                               		: null)}
					</div>
				)} />
			</SingleFieldList>
		</ReferenceManyField>
	)
}

export const ReportsList = () => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const location = useLocation();
    return (
    <List filters={ReportsFilter()} sort={{ field: 'date_from', order: 'DESC' }} >
        <Datagrid bulkActionButtons={false}>
            <ReferenceField source="type_id" reference="operators_portal.report_types">
                <TextField source={translate('localizedName')} />
            </ReferenceField>
            <DateField source="date_from" />
            <DateField source="date_to" />
            <TextField source="timestamp" />
			{
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'monitoring'))
                    ? (<ListCheckboxInput source="is_official" />)
                    : (<BooleanField source="is_official"/>)
            }
            <DownloadButton base64 small resource="reports_vw" fileCol="xlsx_base64" filenameCol="filename" />
            <ReportAttachmentList />
			<UploadA10Button />
            {

//			<FormDataConsumer>
//                {({ formData, ...rest }) => (
//                /*permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator') &&*/ !formData.lock_attachments &&
//                    <SitDropzone targetResource="operators_portal.report_attachments" getDataFnc={createReportAttachmentData} base64 {...rest} />
//                    )
//                }
//            </FormDataConsumer>
            }
			{
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'monitoring'))
                    ? (<ListCheckboxInput source="lock_attachments" />)
                    : (<BooleanField source="lock_attachments"/>)
            }
        </Datagrid>
    </List>);
}

const JourneyFilter = () => {
    const translate = useTranslate();
    const choicesLineSuffix = [
        { value: '', name: translate('lineSuffix.empty') },
        { value: 'BZ', name: translate('lineSuffix.bz') },
        { value: 'ME', name: translate('lineSuffix.me') }
    ];

    return [
        <TextInput source="line_public_code" alwaysOn/>,
        <DateInput source="date" alwaysOn/>,
        <DateInput source="date@gte" label={translate('filter_from')} />,
        <DateInput source="date@lte" label={translate('filter_to')} />,
        <TextInput source="vehicle_id" alwaysOn/>,
        <TextInput source="rideid" alwaysOn/>,
        <SelectInput source="line_suffix" choices={choicesLineSuffix} optionText="name" optionValue="value" alwaysOn/>,
        <ReferenceInput source="operator_id" reference="operators_portal.operators_vw" sort={{ field: 'name', order: 'ASC' }} perPage={100} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    ]
}

export const JourneyList = () => {
    const translate = useTranslate();
    return (
    <List exporter={xlsxExporter} perPage={50} sort={{ field: 'start_time', order: 'ASC' }} filterDefaultValues={{ date: moment().subtract(1, 'days').format('YYYY-MM-DD') }} filters={JourneyFilter()} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} >
        <Datagrid bulkActionButtons={false}>
            <TextField source="line_public_code"/>
            <TextField source="line_suffix"/>
            <DateField source="start_time" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric' }} />
            <TextField source={translate('localizedFields.start_stop_place')} />
            <TextField source={translate('localizedFields.end_stop_place')} />
            <ReferenceField source="operator_id" reference="operators_portal.operators_vw" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="vehicle_id"/>
            <TextField source="rideid"/>
            <ShowButton/>
        </Datagrid>
    </List>)
}

export const JourneyShow = () => {
    return (
    <Show>
        <SimpleShowLayout>
            <ReferenceField source="operator_id" reference="operators_portal.operators_vw" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="date" />
            <TextField source="line_public_code"/>
            <TextField source="line_suffix"/>
            <TextField source="vehicle_id"/>
            <TextField source="rideid"/>
            <DetailsField />
        </SimpleShowLayout>
    </Show>);
}

export const UnplannedChangesList = () => {
    return (
    <List>
        <Datagrid>
            <TextField source="line_public_code"/>
            <ReferenceField source="operator_id" reference="operators_portal.operators_vw">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="begin_ts" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric' }} />
            <DateField source="end_ts" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric' }} />
            <TextField source="description"/>
            <NumberField source="plus_km_l72h" />
            <NumberField source="plus_km_g72h" />
            <NumberField source="minus_km_g72h" />
            <EditButton />
        </Datagrid>
    </List>)
}

const UnplannedChangesDetail = () => {
    return (
    <SimpleForm>
        <TextInput source="line_public_code"/>
        <ReferenceInput source="operator_id" reference="operators_portal.operators_vw" sort={{ field: 'name', order: 'ASC' }} perPage={100}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="begin_ts"/>
        <DateInput source="end_ts"/>
        <TextInput multiline source="description"/>
        <NumberInput source="plus_km_l72h" />
        <NumberInput source="plus_km_g72h" />
        <NumberInput source="minus_km_g72h" />
    </SimpleForm>)
}

export const UnplannedChangesEdit = () => {
    return (
    <Edit>
        <UnplannedChangesDetail/>
    </Edit>)
}

export const UnplannedChangesCreate = () => {
    return (
    <Create>
        <UnplannedChangesDetail/>
    </Create>)
};

export const OutageIntervalsList = () => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
    <List>
        <Datagrid>
            <TextField source="line_public_code"/>
            <ReferenceField source="operator_id" reference="operators_portal.operators_vw">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="begin_ts" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric' }} />
            <DateField source="end_ts" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric' }} />
            <ReferenceField source="reason_const_id" reference="operators_portal.constants">
                <TextField source={translate('localizedName')} />
            </ReferenceField>
            {
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator'))
                    ? (<EditButton/>)
                    : null
            }
        </Datagrid>
    </List>)
};

const OutageIntervalsDetail = () => {
    const translate = useTranslate();
    return (
    <SimpleForm>
        <DateTimeInput source="begin_ts"/>
        <DateTimeInput source="end_ts"/>
        <ReferenceInput source="reason_const_id" reference="operators_portal.constants" filter={{category: "reason_type"}} sort={{ field: translate('localizedName'), order: 'ASC' }} perPage={100}>
            <SelectInput optionText={translate('localizedName')} />
        </ReferenceInput>
        <TextInput multiline source="reason_description"/>
    </SimpleForm>)
}

export const OutageIntervalsEdit = () => {
    return (
    <Edit>
        <OutageIntervalsDetail/>
    </Edit>)
}

export const OutageIntervalsCreate = () => {
    return (
    <Create>
        <OutageIntervalsDetail/>
    </Create>)
}

const ExtraBusInformationFilter = () => {
    const translate = useTranslate();
    return [
        <TextInput source="schedule_year" alwaysOn/>,
        <TextInput source="line_public_code" alwaysOn/>,
        <DateInput source="first_date@gte" label={translate('filter_from')} alwaysOn/>,
        <DateInput source="last_date@lte" label={translate('filter_to')} alwaysOn/>,
        <ReferenceInput source="operator_id_displayed" reference="operators_portal.operators_vw" sort={{ field: 'name', order: 'ASC' }} perPage={100} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    ]
};

export const ExtraBusInformationEdit = () => {
    const { permissions } = usePermissions();
    return (
    <Edit>
        <SimpleForm toolbar={<NoDeleteToolbar/>}>
            <ReferenceField source="operator_id" reference="operators_portal.operators_vw">
                <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                    <TextField source="name" />
                </Labeled>
            </ReferenceField>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="schedule_year" />
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="line_public_code"/>
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="start_time"/>
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <TextField source="start_stop_place"/>
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <DateField source="first_date" />
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <DateField source="last_date" />
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <NumberField source="verkehrstage" />
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <NumberField source="distance_m" />
            </Labeled>
            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                <NumberField source="journey_time_min" />
            </Labeled>
            {
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator'))
                    ? (<BooleanInput source="requires_extra_bus"/>)
                    : (
                            <Labeled sx={{ marginTop: '8px', marginBottom: '24px' }}>
                                <BooleanField source="requires_extra_bus"/>
                            </Labeled>
                      )
            }
        </SimpleForm>
    </Edit>);
}

export const ExtraBusInformationList = () => {
    return (
        <List perPage={50} sort={{ field: 'line_public_code', order: 'ASC' }} filters={ExtraBusInformationFilter()} filterDefaultValues={{ schedule_year: '*' + moment().subtract(15, 'days').format('YYYY') }} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} >
            <Datagrid bulkActionButtons={false}>
                <ReferenceField source="operator_id_displayed" reference="operators_portal.operators_vw">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="schedule_year"/>
                <TextField source="line_public_code"/>
                <TextField source="start_time" />
                <TextField source="start_stop_place" />
                {/*<TextField source={translate('localizedFields.end_stop_place')} />*/}
                <DateField source="first_date" />
                <DateField source="last_date" />
                <NumberField source="verkehrstage" />
                <NumberField source="distance_m" />
                <NumberField source="journey_time_min" />
                <ListCheckboxInput source="requires_extra_bus" />
                {/*<EditButton/>*/}
            </Datagrid>
        </List>
    );
};

const ManualPassengerCountingFilter = () => {
    return [
        <TextInput source="line_public_code" alwaysOn/>,
        <DateInput source="date" alwaysOn/>,
        <ReferenceInput source="operator_id" reference="operators_portal.operators_vw" sort={{ field: 'name', order: 'ASC' }} perPage={100} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    ];
}

export const ManualPassengerCountingList = () => {
    const { permissions } = usePermissions();
    return (<List perPage={50} sort={{ field: 'date', order: 'DESC' }} filters={ManualPassengerCountingFilter()} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} >
        <Datagrid>
            <TextField source="line_public_code"/>
            <ReferenceField source="operator_id" reference="operators_portal.operators_vw">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="date" />
			<NumberField source="counting_inbound" />
			<NumberField source="counting_outbound" />
            {
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'operator'))
                    ? (<EditButton/>)
                    : null
            }
        </Datagrid>
    </List>)
}

const ManualPassengerCountingDetail = () => {
    return (
    <SimpleForm>
		<TextInput source="line_public_code"/>
        <DateInput source="date" defaultValue={ moment() }/>
        <NumberInput source="counting_inbound" />
		<NumberInput source="counting_outbound" />
        <TextInput multiline source="description"/>
    </SimpleForm>)
}

export const ManualPassengerCountingEdit = () => {
    return (
    <Edit>
        <ManualPassengerCountingDetail/>
    </Edit>)
}

export const ManualPassengerCountingCreate = () => {
    return (
    <Create>
        <ManualPassengerCountingDetail/>
    </Create>)
}


export const SegmentChangesLogList = () => {
    const { permissions } = usePermissions();
    return (<List perPage={50} sort={{ field: 'last_update_timestamp', order: 'DESC' }} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} >
        <Datagrid>
            <ReferenceField source="service_link_ref" reference="operators_portal.service_link_mvw">
                <TextField source="q" />
            </ReferenceField>
			<TextField source="description" multiline/>
            <DateField source="last_update_timestamp" />
            {
                (permissions && (permissions.role === 'sitadmin' || permissions.role === 'sta_operator'))
                    ? (<EditButton/>)
                    : null
            }
        </Datagrid>
    </List>)
}

const SegmentChangesLogDetail = () => {
    return (
    <SimpleForm>
		<ReferenceInput source="service_link_ref" reference="operators_portal.service_link_mvw" filterToQuery={searchText => ({ q: `*${searchText}*` })} perPage={100} enableGetChoices={({ q }) => q && q.length >= 2} >
            <AutocompleteInput filterToQuery={searchText => ({ q: `*${searchText}*` })} optionText="q"  fullWidth/>
        </ReferenceInput>
		<TextInput source="description" multiline fullWidth/>
    </SimpleForm>)
}

export const SegmentChangesLogEdit = () => {
    return (
    <Edit>
        <SegmentChangesLogDetail/>
    </Edit>)
}

export const SegmentChangesLogCreate = () => {
    return (
    <Create>
        <SegmentChangesLogDetail/>
    </Create>)
}

const SasaJourneyCodesFilter = () => {
    const translate = useTranslate();

    const choicesLineSuffix = [
        { value: '', name: translate('lineSuffix.empty') },
        { value: 'BZ', name: translate('lineSuffix.bz') },
        { value: 'ME', name: translate('lineSuffix.me') }
    ];

    return [
        <TextInput source="line_public_code" alwaysOn/>,
        <DateInput source="date" alwaysOn/>,
        <DateInput source="date@gte" label={translate('filter_from')} />,
        <DateInput source="date@lte" label={translate('filter_to')} />,
        <ReferenceInput source="mandant" reference="operators_portal.mandants_vw" sort={{ field: 'name', order: 'ASC' }} perPage={100} alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
        /*<SelectInput source="line_suffix" choices={choicesLineSuffix} optionText="name" optionValue="value" alwaysOn />*/
    ];
}

export const SasaJourneyCodesList = () => {
    const translate = useTranslate();
    return (
    <List exporter={toXlsx} perPage={50} sort={{ field: 'departure_time', order: 'ASC' }} filterDefaultValues={{ date: moment().format('YYYY-MM-DD') }} filters={SasaJourneyCodesFilter()} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} >
        <Datagrid bulkActionButtons={false}>
            <TextField source="line_public_code"/>
            <TextField source="lot_identifier" />
            {/*<TextField source="line_suffix"/>*/}
			<DateField source="date" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} />
            <TextField source="departure_time" />
			<TextField source="arrival_time" />
			<TextField source={translate('localizedFields.start_stop_place')} />
			<TextField source="start_global_id" />
			<TextField source="end_global_id" />
			<TextField source="line_private_code" />
			<TextField source="journey_private_code" />
			<TextField source="itcs_code" />
			<TextField source="mandant" />
			{/*<TextField source="linien_kursnummer" />*/}
			{/*<TextField source="kursnummer" />*/}
        </Datagrid>
    </List>)
}

const SegmentChangeFilter = () => {
    const translate = useTranslate();
    return [
        <NumberInput source="schedule_year" alwaysOn/>,
        <TextInput source={translate('localizedFields.from_place_name')} alwaysOn/>,
        <TextInput source={translate('localizedFields.to_place_name')} alwaysOn/>,
        <ReferenceInput source="operational_context_ref" reference="operators_portal.segment_changes_filter_operational_context_vw" sort={{ field: translate('localizedFields.name'), order: 'ASC' }} perPage={100} alwaysOn>
            <SelectInput optionText={translate('localizedFields.name')} />
        </ReferenceInput>,
    ]
};

export const SegmentChangeList = () => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
        <List perPage={50} filters={SegmentChangeFilter()} filterDefaultValues={{ schedule_year: moment().subtract(15, 'days').format('YYYY') }} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} >
            <Datagrid bulkActionButtons={false}>
                {/*<TextField source="schedule_year"/>*/}
                <TextField source={translate('localizedFields.schedule_year')} />
                <TextField source={translate('localizedFields.from_place_name')} />
                <TextField source={translate('localizedFields.to_place_name')} />
                <TextField source={translate('localizedFields.operational_context_name')} />
                <NumberField source="distance_jd" />
                <NumberField source="distance_cns" />
                <DateField source="last_data_update" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric' }} />
                {[
                    (permissions.role === 'sitadmin' || permissions.role === 'operator')
                        ? (<ListCheckboxInput source="check_operator" />)
                        : (<BooleanField source="check_operator"/>)
                ]}
                {[
                    (permissions.role === 'sitadmin' || permissions.role === 'sta_operator')
                        ? (<ListCheckboxInput source="check_sta" />)
                        : null
                ]}
                {/*<EditButton/>*/}
            </Datagrid>
        </List>
    );
};

const ItcsEfaFilter = () => {
    const translate = useTranslate();

    const choicesBooleanAndUndefined = [
        { value: 'undefined', name: translate('booleanAndUndefined.undefined') },
        { value: true, name: translate('booleanAndUndefined.yes') },
        { value: false, name: translate('booleanAndUndefined.no') }
    ];

    const choicesSource = [
        { value: 'IVU.fleet', name: 'IVU.fleet' },
        { value: 'IVU.box', name: 'IVU.box' }
    ];

    const choicesErrClasses = [
        { id: 'ERR_1', name: translate('resources.operators_portal.itcs_efa.errorClasses.ERR_1') },
        { id: 'ERR_2', name: translate('resources.operators_portal.itcs_efa.errorClasses.ERR_2') },
        { id: 'ERR_3', name: translate('resources.operators_portal.itcs_efa.errorClasses.ERR_3') },
        { id: 'ERR_4', name: translate('resources.operators_portal.itcs_efa.errorClasses.ERR_4') },
        { id: 'ERR_5', name: translate('resources.operators_portal.itcs_efa.errorClasses.ERR_5') },
    ];

    const choicesLineSuffix = [
        { value: '', name: translate('lineSuffix.empty') },
        { value: 'BZ', name: translate('lineSuffix.bz') },
        { value: 'ME', name: translate('lineSuffix.me') }
    ];

    return [
        <ReferenceInput source="lot_id" reference="operators_portal.lots_vw" sort={{ field: 'ordering', order: 'ASC' }} perPage={12}>
            <SelectInput optionText={translate('localizedNameDefaultDe')} />
        </ReferenceInput>,
        <DateInput source="datum" alwaysOn/>,
        <TimeInput source="abfahrtszeit_without_date@gte" type="time" alwaysOn options={{ format: 'HH:mm:ss' }}  label="resources.operators_portal.itcs_efa.fields.abfahrtszeit_von"  />,
        <TimeInput source="abfahrtszeit_without_date@lte" type="time" alwaysOn options={{ format: 'HH:mm:ss' }}  label="resources.operators_portal.itcs_efa.fields.abfahrtszeit_bis"  />,
        <ReferenceInput source="operator_id_displayed" reference="operators_portal.operators_vw" sort={{ field: 'name', order: 'ASC' }} perPage={100}>
            <SelectInput optionText="name" />
        </ReferenceInput>,
        <SelectInput source="source" choices={choicesSource} optionText="name" optionValue="value" />,
        <TextInput source="line_public_code" alwaysOn/>,
        <SelectInput source="line_suffix" choices={choicesLineSuffix} optionText="name" optionValue="value" alwaysOn />,
        <TextInput source="kurs"/>,
        <TextInput source="umlauf"/>,
        <TextInput source="fahrt@like" label="resources.operators_portal.itcs_efa.fields.fahrt" alwaysOn/>,
        <TextInput source="verlaufsnummer"/>,
        <TextInput source="start"/>,
        <TextInput source={translate('localizedFields.start_stop_place_name') + "@like"} label="resources.operators_portal.itcs_efa.fields.start_stop_place_name" alwaysOn />,
        <TextInput source="ziel"/>,
        <TextInput source={translate('localizedFields.ziel_stop_place_name') + "@like"}  label="resources.operators_portal.itcs_efa.fields.ziel_stop_place_name" alwaysOn />,
        <TextInput source="haltepunkt@like"  label="resources.operators_portal.itcs_efa.fields.haltepunkt" alwaysOn />,
        <TextInput source={translate('localizedFields.haltepunkt_stop_place_name') + "@like"}  label="resources.operators_portal.itcs_efa.fields.haltepunkt_stop_place_name" alwaysOn />,
        <TextInput source="fahrzeugnummer@like"  label="resources.operators_portal.itcs_efa.fields.fahrzeugnummer" alwaysOn/>,
        <SelectArrayInput source="error_class" choices={choicesErrClasses} alwaysOn></SelectArrayInput>
    ];
}


const ItcsEfaErrorClassesField = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    if(record.error_class)
    return (
        <Chip label={translate('resources.operators_portal.itcs_efa.errorClasses.' + record.error_class)} />
    )
    return (
        <span></span>
    )
}
export const ItcsEfaList = () => {
    const translate = useTranslate();

    return (
        <List   style={{flexGrow: 1}} sx={{
            '& .MuiPaper-root': {
                overflow: 'auto',
                height: '0px',
                flexGrow: 1
            },
            '& .RaDatagrid-root': {
                display: 'flex'
            },
            '& .RaDatagrid-tableWrapper': {
                width: '0px',
                flexGrow: 1
            },
        }} exporter={xlsxExporter} perPage={50} sort={{ field: 'abfahrtszeit_soll', order: 'ASC' }} filterDefaultValues={{ datum: moment().subtract(1, 'days').format('YYYY-MM-DD') }} filters={ItcsEfaFilter()} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} >
            <Datagrid bulkActionButtons={false}  style={{whiteSpace: 'nowrap'}}>
                <DateField source="datum" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} />
                <TextField source="source"/>
                <ReferenceField source="operator_id_displayed" reference="operators_portal.operators_vw">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="line_public_code"/>
                <TextField source="line_suffix"/>
                <TextField source="fahrt"/>
                <TextField source="kurs"/>
                <DateField source="abfahrtszeit_soll" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric', second:'numeric' }} />
                <DateField source="abfahrtszeit_ist" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric', second:'numeric' }} />
                <TextField source="fahrzeugnummer"/>
                <TextField source="start"/>
                <TextField source={translate('localizedFields.start_stop_place_name')} />
                <TextField source="ziel"/>
                <TextField source={translate('localizedFields.ziel_stop_place_name')} />
                <TextField source="basisversion"/>
                {/*<TextField source="fahrernummer"/>*/}
                {/*<TextField source="fahrernummer_extern"/>*/}
                {/*<TextField source="fahrttyp"/>*/}
                {/*<TextField source="fahrweg_kurzbezeichner"/>*/}
                <TextField source="richtung"/>
                {/*<TextField source="unternehmen"/>*/}
                {/*<TextField source="zaehleinrichtung"/>*/}
                <TextField source="verlaufsnummer"/>
                <TextField source="haltepunkt"/>
                <TextField source={translate('localizedFields.haltepunkt_stop_place_name')} />
                <TextField source="haltezeit"/>
                <DateField source="soll_an" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric', second:'numeric' }} />
                <DateField source="ist_an" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric', second:'numeric' }} />
                <TextField source="delta_an"/>
                <DateField source="soll_ab" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric', second:'numeric' }} />
                <DateField source="ist_ab" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour:'numeric', minute:'numeric', second:'numeric' }} />
                <TextField source="delta_ab"/>
                <TextField source="st_aend_urs"/>
                <TextField source="fahrgastwechselzeit"/>
                <ItcsEfaErrorClassesField />
            </Datagrid>
        </List>)
}
