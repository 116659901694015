export default {
    event_type: {
        NO_JOURNEY: 4,
        INCOMPLETE_JOURNEY: 6,
        REGULAR_JOURNEY: 26,
        REINFORCEMENT_JOURNEY: 27,
        ADDITIONAL_JOURNEY: 28,
        ROUTE_CHANGE: 29,
        PLANNED_JOURNEY: 46
    },
    reason_type: {
        NO_REASON: 10,
        AVM_MISSING: 11,
        AVM_ERROR: 12,
        PERSONNEL: 13,
        VEHICLE: 14,
        INCIDENT_OPERATOR: 15,
        INCIDENT_3RD_PARTY: 16,
        TRAFFIC: 17,
        EVENT: 18,
        OTHER_3RD_PARTY: 19,
        PASSENGER: 20,
        ROADBLOCK: 21,
        DATA_QUALITY: 22,
        STRIKE_GARANTEED_SERVICE: 8,
        STRIKE_NOT_GARANTEED_SERVICE: 9,
        NO_PASSENGERS: 23,
        MAINTENANCE: 24,
        STORM: 25,
        INFRASTRUCTURE: 44,
    },
    reason_type_delay: {
        DELAY_NO_REASON: 31,
        DELAY_STRIKE_GARANTEED_SERVICE: 32,
        DELAY_STRIKE_NOT_GARANTEED_SERVICE: 33,
        DELAY_INTERCHANGE_RULE: 34,
        DELAY_PERSONNEL: 35,
        DELAY_VEHICLE: 36,
        DELAY_INCIDENT_OPERATOR: 37,
        DELAY_INCIDENT_3RD_PARTY: 38,
        DELAY_TRAFFIC: 39,
        DELAY_EVENT: 40,
        DELAY_OTHER_3RD_PARTY: 41,
        DELAY_PASSENGER: 42,
        DELAY_ROADBLOCK: 43,
    }
}
